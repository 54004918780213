import { SelectedDrawerState } from "../actions/drawer";

const defaultState = {
    setDrawerState: '',
}

export default (state = defaultState, action) => {
    switch(action.type) {
        case 'SET_SELECTED_DRAWER_STATE' :
        return {
            ...state,
            setDrawerState: action.drawerState
        }  

        default:
            return state;
    }
}