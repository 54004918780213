import {getAxios} from './axiosUtil';

export const encodeZohoRefreshTokenApi = (text, tk_type) => {

    let data = {
        text,
        tk_type
    }

    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update_enc_tk`, data);
}

export const logoutApi = () => {
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`);
}

export const addMemberToOrgApi = (service, email, role, entity_id, entity_name) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/invite`, data);
}

//resend invitation to member to join org api 
export const resendInvitationApi = (service, email, role, entity_id, entity_name, entity_email) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name,
        entity_email
    }
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/resend_invite`, data);
}


export const updateUserLoginApi = () => {
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update_user_login`);
}

export const authenticateApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/authenticate`, param)
}