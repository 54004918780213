import { consoleToLog } from '../util/AppUtil';
import {getAxios} from './axiosUtil';

export const getZohoRefereshTokenApi = (organization_id, code, zoho_domain, redirect_url) => {
    
    let data = {
        code,
        zoho_domain,
        redirect_url
    }

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/zoho_tokens`, data);
}

//get Organization List API
export const organizationListApi = () => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organizations`);
}

export const getUserDetailsObjApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/user/me`);
}

//organization details (member + labels)
export const organizationDetailsApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/details`);
}

export const getZohoOrganizationListApi = (organization_id, tk_type, zoho_access_token, api_domain) => {
    const param = {
        params: {
            tk_type,
            zoho_access_token,
            api_domain
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/erp_organizations`, param);
}

export const addCompaniesApi = (organization_id, companies) => {
    let data = {
        companies
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/add`, data);
}

export const getCompaniesApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/companies`);//?source_erp_software=zoho
}

export const getAttachmentUploadURLApi = (organization_id, filename, filetype, company_id) => {
    let data = {filename, filetype}
    consoleToLog("filedata: ", data);
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/presigns3url`, data);
}

export const uploadAttachmentApi = async (organization_id, filename, filetype, file, company_id) => {
    const uploadURl = await getAttachmentUploadURLApi(organization_id, filename, filetype, company_id);
    const options = {
            headers: {
            "Content-Type": filetype,
            }
        };
        getAxios().defaults.headers.common = {};
    const data = await getAxios().put(uploadURl.data.data.returnData.signedRequest, file, options)
    return {signed_url: uploadURl.data.data.returnData.signedRequest, file_name: uploadURl.data.data.returnData.url};
        
}

export const createInvoiceRecordApi = (organization_id, company_id, finalAttachments, isPurchase) => {
    let data = {attachments: finalAttachments, isPurchase}
    consoleToLog("filedata: ", data);
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/invoice/create`, data);   
}

export const getInvoiceListApi =(organization_id, page, text, invoice_status, company_id, invoice_type)=>{
    const param = {
        params: {
            page,
            text,
            invoice_status,
            company_id,
            invoice_type
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoices`, param);
}

export const createInvitationApi = (organization_id, email, role) => {
    let data = {email, role}
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/user/invitation/create`, data);
}

export const getPendingInvitationsListApi = (organization_id) => {
    
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/pending_invitations`);
}

export const updateOrgMemberApi = (organization_id, organization_member_id, access_level) => {
    let data = {
        organization_member_id,
        access_level
    }
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/member/update`, data);
}

export const getCompanyDetailsApi = (organization_id, company_id, erp_source_id, tk_type, zoho_access_token, api_domain, entity, invoice_id, zoho_bill_id) => {
    const param = {
        params: {
            erp_source_id,
            tk_type,
            zoho_access_token,
            api_domain,
            entity,
            invoice_id,
            zoho_bill_id
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/details`, param);

}

export const getVendorsNameApi = (organization_id, company_id, text, erp_source_id, tk_type, vendor_gst_number, suggested_vendor, zoho_access_token, api_domain) => {
    const param = {
        params: {
            text,
            erp_source_id,
            tk_type,
            vendor_gst_number,
            suggested_vendor,
            zoho_access_token, 
            api_domain
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/vendors`, param);

}

export const getSignedUrlApi = (organization_id, filepath) => {
    let data = {filepath}
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/getsigns3url`, data);
}

export const updateBillApi = (organization_id, company_id, invoice_id, erp_source_id, tk_type, bill_number, reference_number,
    vendor_id, date, due_date, adjustment, adjustment_description, line_items, is_draft, payment_terms, payment_terms_label, gst_treatment, gst_no, 
    source_of_supply, destination_of_supply, ocr_extracted_vendor_name, tds_percent, is_tds_amount_in_percent, tds_tax_id, tcs_percent, is_tcs_amount_in_percent,
    tcs_tax_id, invoice_status, erp_invoice_id, invoice_json, zoho_access_token, api_domain, company_name, custom_fields, exchange_rate, notes) => 
    
    {
        let data = {
            erp_source_id, 
            tk_type, 
            bill_number, 
            reference_number,
            vendor_id, 
            date, 
            due_date, 
            adjustment, 
            adjustment_description, 
            line_items, 
            is_draft, 
            payment_terms, 
            payment_terms_label, 
            gst_treatment, 
            gst_no, 
            source_of_supply, 
            destination_of_supply, 
            ocr_extracted_vendor_name, 
            tds_percent, 
            is_tds_amount_in_percent, 
            tds_tax_id,
            tcs_percent, 
            is_tcs_amount_in_percent, 
            tcs_tax_id,
            invoice_status,
            erp_invoice_id,
            invoice_json,
            zoho_access_token, 
            api_domain,
            company_name,
            custom_fields,
            exchange_rate,
            notes
        }
        //console.log('data', data);
        
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/invoice/${invoice_id}/update`, data);
}

export const getRefereshedZohoTokensApi = (organization_id, tk_type, redirect_url, email) => {
    let data = {tk_type, redirect_url, email}
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/refreshed_zoho_tokens`, data);
}


export const validateInvoiceApi = (organization_id, invoice_id, amount, bill_number, vendor_gstin, receiver_gstin) => {

    let data = {
        amount,
        bill_number,
        vendor_gstin,
        receiver_gstin
    }

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/validate`, data);
}

export const addAssigneeApproverApi = (organization_id, assignee, apiPath, apiStr) => {
    let data = {assignee}

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/${apiPath}/${apiStr}`, data);
}

export const invoiceStatusCountsApi = (organization_id, company_id, invoice_type) => {
    const param = {
        params: {
            company_id, invoice_type
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice_status_counts`, param);
}

export const getCompaniesListApi = (organization_id, source_erp_software, text, apiPath) => {
    const param = {
        params: {
            source_erp_software,
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/${apiPath}`, param);
}   

export const getVendorDetailsApi = (organization_id, vendor_id, erp_source_id, zoho_access_token, api_domain) => {

    const param = {
        params: {
            erp_source_id,
            zoho_access_token,
            api_domain
        }
    }
    console.log("**param", param)

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/vendor/${vendor_id}/vendor_details`, param);
}

export const getCloneItemsFromLastInvoiceApi = (organization_id, company_id, vendor_id) => {
    const param = {
        params: {
            vendor_id,
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}`, param);
}

export const deleteInvoiceApi = (organization_id, invoice_id) => {

    return getAxios().delete(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/delete`);
}

export const removeZohoAccessApi = (organization_id, email) => {
    const data = {
        email
    }
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/erp_integration/zoho/remove`, data);
}

export const updateOrgInfoApi = (organization_id, name, country) => {

    let data = {
        name,
        country
    }

    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/update`, data);
}

export const updateUserProfileApi = (organization_id, firstname, lastname) => {

    let data = {
        firstname,
        lastname
    }

    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/profile/update`, data);
}

export const getLoggedInUserCompaniesApi = (organization_id, source_erp_software) => {
    /* const param = {
        params: {
            source_erp_software
        }
    } */
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/authorized/companies`);
}

export const archiveRestoreDeleteCompanyApi = (organization_id, company_id, apiPath) => {
    let path = apiPath === 'delete' ? getAxios().delete(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}${apiPath ? `/${apiPath}` : ''}`)
                                    : getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}${apiPath ? `/${apiPath}` : ''}`);

    return path;
}

export const createCheckoutSessionApi = (organization_id, lookup_key, quantity) => {
    let data = {lookup_key, quantity}

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/create_checkout_session`, data);
} 

export const createCustomerPortalSesionApi = (organization_id, stripe_customer_id) => {

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/cutomer_portal_session/${stripe_customer_id}`);
} 

export const uploadInvoiceFilesApi = (organization_id, invoice_id, zoho_access_token, api_domain, bill_id, erp_source_id, invoice_bill_number, is_create_bill, files) => {
    var data = new FormData();
    data.set('zoho_access_token', zoho_access_token);
    data.set('api_domain', api_domain);
    data.set('bill_id', bill_id);
    data.set('erp_source_id', erp_source_id);
    data.set('invoice_bill_number', invoice_bill_number);
    data.set('is_create_bill', is_create_bill);
    for(var i = 0; i < files.length; i++){
        data.append('files', files[i]);
    }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/file_uploads`, data, header);
} 


export const downloadInvoiceAttachmentApi = (organization_id, document_id, zoho_access_token, api_domain, erp_source_id, zoho_bill_id) => {
    const param = {
        params : {
            zoho_access_token, 
            api_domain, 
            erp_source_id, 
            zoho_bill_id
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/document/${document_id}`, param);
} 

export const deleteInvoiceAttachmentApi = (organization_id, invoice_id, document_id, zoho_access_token, api_domain, erp_source_id, bill_id) => {
    const data = {
            zoho_access_token, 
            api_domain, 
            erp_source_id, 
            bill_id
    }

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/document/${document_id}`, data);
}

export const getRecordPaymentOptionsApi = (organization_id, invoice_id, zoho_access_token, api_domain, erp_source_id, zoho_bill_id) => {

    const param = {
        params: {
            zoho_access_token, 
            api_domain, 
            erp_source_id, 
            zoho_bill_id
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/options/record_payment`, param);
}

export const getExchangeRateApi=(organization_id, date, base, symbol)=>{
    const param = {
        params: {
            base, 
            symbol
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/exchange_rate/${date}`, param);
}

export const createRecordPaymentApi = (organization_id, invoice_id, zoho_access_token, api_domain, erp_source_id, bill_id, json, files) => {

    var data = new FormData();
    data.set('zoho_access_token', zoho_access_token);
    data.set('api_domain', api_domain);
    data.set('erp_source_id', erp_source_id);
    data.set('bill_id', bill_id);
    data.set('json', json);
    for(var i = 0; i < files.length; i++){
        data.append('files', files[i]);
    }
    
    const header = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'multipart/form-data'
        }
    };

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/record_payment/create`, data, header);
}

export const deleteRecorededPaymentApi = (organization_id, invoice_id, payment_id, zoho_access_token, api_domain, erp_source_id, bill_id) => {

    const data = {
            zoho_access_token, 
            api_domain, 
            erp_source_id,
            bill_id
    }

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/record_payment/${payment_id}/delete`, data);
}

export const purchaseScanPointsApi = (organization_id, quantity) => {

    const data = {
            quantity
    }

    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/scan_points/create_checkout_session`, data);
}

export const addItemAndSynonymApi = (organization_id, company_id, line_item, source_type) => {
        let data = {
            line_item, 
            source_type
        }
        
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/item/add`, data);
}

export const addLedgerAndSynonymApi= (organization_id, company_id, line_item, source_type) => {
    let data = {
        line_item, 
        source_type
    }
    
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/ledger/add`, data);
}

export const updateInvoiceObjCurrencyApi = (organization_id, invoice_id,currency) => {
    let data = {
        currency
    }
    
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/currency/update`, data);
}

export const getVendorsByTextApi = (organization_id, zoho_access_token, api_domain, erp_source_id, text) => {
    const param = {
        params: {
            zoho_access_token, 
            api_domain, 
            erp_source_id,
            text
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/vendors_by_text`, param);
}

export const addTallyTaxLedgerEntryApi = (organization_id, company_id, record)=>{
    let data = {
        record
    }
    
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/add_tax_ledger_record`, data);
}

export const addTallyVendorAndSynonymApi = (organization_id, company_id, type, vendor, tax_ledger, capturedVendorName, tcs_ledger_name) =>{
    let data = {
        type,
        vendor,
        tax_ledger, 
        capturedVendorName,
        tcs_ledger_name
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/add_vendor_and_synonym`, data);
}

export const updateTallyBillApi = (organization_id, company_id, invoice_id, invoice_json) =>{
    let data = {
        invoice_json
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${company_id}/invoice/${invoice_id}/update_tally_bill`, data);
}

export const getSupportedSoftwareListApi = (organization_id) => {
    
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/supported_erp_softwares`);
}

export const updateOrgErpSoftwaresApi = (organization_id, erp_softwares) => {
    let data = {
        erp_softwares
    }
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/update_supported_erp_softwares`, data);
}

export const getConnectorAppVersionApi = (organization_id) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/get_connector_versions`);
}

export const callDeleteOrgMemberApi=(organization_id, oldMemberId, replacementMemberId)=>{
    let data = {
        new_replacement_members_user_id: replacementMemberId
    }
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/organization_user/${oldMemberId}/delete`, data)
}

export const getZohoUserDetail= (organization_id, zoho_token) => {
    const param = {
        params : {
            zoho_token
        }
    }

    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/zoho_user_detail`, param);
}