import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/captureAuth';
import captureOrganizationsReducer from '../reducers/captureOrganizations';
import loadingReducer from '../reducers/loading';
import captureSelectedOrganizationReducer from '../reducers/captureSelectedOrganization';
import drawerReducer from '../reducers/drawer';
import captureZohoTokenReducer from '../reducers/captureZohoToken';
import captureZohoTokenListReducer from '../reducers/captureZohoTokenList';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            captureAuth: authReducer,
            drawerState: drawerReducer,
            captureOrganizations: captureOrganizationsReducer,
            captureSelectedOrganizationInfo: captureSelectedOrganizationReducer,
            loading: loadingReducer,
            captureZohoToken: captureZohoTokenReducer,
            captureZohoTokenList: captureZohoTokenListReducer
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj