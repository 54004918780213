import React from 'react';
import {Grid, Button, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AccessLevel,shouldDisable } from '../util/AppUtil';
import {connect} from 'react-redux';
import { setSelectedDrawerState, SelectedDrawerState } from '../actions/drawer';
import { useNavigate } from 'react-router-dom';
import {connectToERPSoftware} from '../util/AppUtil';

const noActivityText = {
    fontSize: "16px",
    fontWeight: "500",
};

const noActivityButton = {
    fontSize: "15px",
    width: "190px",
    marginTop: "8px",

};

const buttonPlusIcon = {
    marginRight:'4px', 
    fontSize:'1.2rem', 
    display:'flex',
    alignItems:'center',
    marginTop:'-2px'
};

const NoErpOrCompaniesComponent = (props) => {
    const navigate = useNavigate();

    const {access_level} = props.captureSelectedOrganization;
    const accessLevelAdmin = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.ADMIN);

    const closeAddBillDrawer = () => {
        props.fromAddBillDrawer && props.handleCloseAddBillDrawer();
        !props.fromAddBillDrawer && props.handleCloseAddCompanyDrawer();
    }

    const onConnectClick = () => {
        connectToERPSoftware(navigate, props)
        closeAddBillDrawer();
    }

    const onAddCompanies = () => {
        props.handleOpenAddCompanyDrawer();
        props.fromAddBillDrawer && props.handleCloseAddBillDrawer();
    }

    return (
        <div style={{padding:'32px 16px'}}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item container direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sm={6}>
                            <img alt="No Invoices Found" src={props.src}
                                style={{
                                    height: "12rem",
                                    width: "15rem",
                                }}/>
                    </Grid>
                    <Grid item container direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Typography sx={noActivityText}>
                            {props.message}
                        </Typography>
                    </Grid>
                    <Grid item container  alignItems="center" spacing={2}
                        justifyContent="center">
                            <Grid item>
                                {props.addErpSoftware ?
                                <Button variant="contained" 
                                    size="large"
                                    color='secondary'
                                    onClick={onConnectClick}
                                    sx={noActivityButton}>
                                        Connect
                                </Button>
                                :
                                !accessLevelAdmin &&
                                <Button variant="contained" 
                                    size="large"
                                    color='secondary'
                                    onClick={onAddCompanies}
                                    sx={noActivityButton}>
                                        <AddCircleOutlineIcon fontSize='small' sx={buttonPlusIcon}/>
                                        Add Company
                                </Button>
                                }
                            </Grid>
                    </Grid>
                </Grid>
            </div>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(NoErpOrCompaniesComponent);