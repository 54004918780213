import React, {useState} from 'react';
import {getRefereshedZohoTokensApi, getZohoOrganizationListApi, getZohoUserDetail} from './captureAppService';
import {consoleToLog, getZohoTokenObj} from '../util/AppUtil';

const handleErrors = (response) => {
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};

export const getRefreshedZohoToken = (organization_id, tk_type, props, email) => {
    return getRefereshedZohoTokensApi(organization_id, tk_type, process.env.REACT_APP_ZOHO_REDIRECT_URL, email)
            .then(async(response) => {
                const res = response.data;
                consoleToLog('Response getRefereshedZohoTokensApi', res);
                props.setZohoTokenObj(getZohoTokenObj(res));

                const userDetail=await getZohoUserDetail(organization_id, res.result?.access_token);
                consoleToLog("**Response zohouserDetail: ", userDetail.data);

                //get zoho org list
                let zohoOrgList=await getZohoOrganizationListApi(organization_id, 'zoho', res.result?.access_token, res.result?.api_domain)
                zohoOrgList=(zohoOrgList.data.zoho_orgs && zohoOrgList.data.zoho_orgs.length>0) ? zohoOrgList.data.zoho_orgs.map((org)=>{return {name: org.name, organization_id: org.organization_id}}): []
                consoleToLog("Response zohoOrgList: ", zohoOrgList);

                //multiple zoho token
                res.email=userDetail?.data?.userDetail?.Email;
                res.zohoOrgList=zohoOrgList;
                props.addOrUpdateZohoTokenToList(getZohoTokenObj(res));

                return res;
            })
            .catch((error) => {
                consoleToLog('Error getRefereshedZohoTokensApi', error);
                throw error
            })
}