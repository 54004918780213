import axios from 'axios';
import { consoleToLog } from '../util/AppUtil';

const UNAUTHORIZED = 401;

export const getAxios = () => {

    axios.interceptors.request.use(
        (config) => {
            config.withCredentials = true;
            return config;
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    
    if (axios.interceptors.response.handlers.length === 0) {
        axios.interceptors.response.use(
            response => response,
            error => {
                consoleToLog('**error:', error.config.url);
                if((!error.config.url.includes('connectToTally') && !error.config.url.includes('http://localhost:9393'))) {
                    if (!error.response || !error.response.status) {
                        consoleToLog("1error : ", error);
                        if (!isConnectionExists()) {
                            alert('Something went wrong!, Please try again.');
                            return Promise.reject(error);
                        } else {
                            alert('Something went wrong!, Please try again.');
                            return Promise.reject(error);
                        }
                    }
                
                    const { status } = error?.response;
                    if (status === UNAUTHORIZED) {
                        error.response.data.message = "Session Expired, please login again!"
                        localStorage.clear();
                        const url = `/?logout=true&back_url=${window.location.pathname}`;
                        window.open(url, '_self');
                        return Promise.reject(error);
                    }
                    return Promise.reject(error);
                }
                if(error.config && error.config?.url?.includes('connectToTally')){
                    //alert('Unable to connect to tally, It seems invalid tally host or port is set. Kindly update from connector app!');
                    return Promise.reject(error);
                }
            }
        );
    }
    return axios;
};


function isConnectionExists() {
    var xhr = new XMLHttpRequest();
    var file = "https://www.kirupa.com/blank.png";
    var randomNum = Math.round(Math.random() * 10000);

    xhr.open('HEAD', file + "?rand=" + randomNum, true);
    xhr.send();

    xhr.addEventListener("readystatechange", processRequest, false);

    function processRequest(e) {
        if (xhr.readyState == 4) {
            if (xhr.status >= 200 && xhr.status < 304) {
                return true;
            } else {
                return false;
            }
        }
    }
}