export default (state = [], action) => {
    switch (action.type) {
        case 'SET_ORGANIZATIONS':
            return action.organizations;
        
        case 'UPDATE_ORGANIZATION':
            return state.map((org) => {
                if (org.organization.id === action.organization.id) {
                    return {
                        ...org,
                        organization: action.organization
                    };
                } else {
                    return org;
                };
            });
        
        case 'CLEAR_ORGANIZATIONS':
            return [];

        default:
            return state;
    }
};