const defaultState = {
    selectedOrganization: {},
    members: [],
    user:{},
    onboarding: false,
    erpSoftwareList: []
}

//set selected organization
export default (state = defaultState, action) => {
    switch (action.type) {

        /*** selected organization*/
        case 'SET_SELECTED_ORG':
            return {
                ...state,
                selectedOrganization: action.organization
            }

        /*** Org members */
        case 'SET_ORG_MEMBERS':
            return {
                ...state,
                members: action.members
            }

            case 'ADD_ORG_MEMBER':
                return {
                    ...state,
                    members: [
                        ...state.members,
                        action.member
                    ]
                }   
    
             /**change member role */
            case 'UPDATE_ORG_MEMBER_ROLE': 
                return {
                    ...state,
                    members: state.members.map((member) => {
                        if(member.id === action.organization_member_id) {
                            return {
                                ...member,
                                access_level: action.access_level
                            }
                        } else {
                            return member;
                        }
                    })
                }  
                
             /**remove member from org */
            case 'REMOVE_ORG_MEMBER':
                return {
                    ...state,
                    members: state.members.filter(({id}) => id !== action.organization_member_id)
                }   

            case 'SET_USER_OBJ' :
                return {
                    ...state,
                    user : action.user_obj
                }

            case 'SET_SELECTED_ORG_ONBOARDING' :
                return {
                    ...state,
                    onboarding : action.onboarding
                }

            case 'UPDATE_SELECTED_ORG':
                return {
                    ...state,
                    selectedOrganization: {
                        ...state.selectedOrganization,
                        organization: action.organization
                    }
                }    

            case 'UPDATE_USER':
                return {
                    ...state,
                    user: action.user
                }

            case 'SET_SUPPORTED_SOFTWARE_LIST':
                return {
                    ...state,
                    erpSoftwareList: action.software_list
                }


            case 'SET_CLEAR_ORG_STATE':
                return defaultState;

                default:
                return state;
    }
};