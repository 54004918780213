import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, Grid, Typography, Button, TextField} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import PurchaseCardComponent from './PurchaseCardComponent';
import {purchaseScanPointsApi} from '../services/captureAppService';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';

const MuiDialogpaper = {
    scrollbarWidth: 'none !important',
};

const yesButtonStyle = {
    borderRadius: '5px',
    background: '#318415',
    color:'#fff',
    width:'110px',
    '&:hover': {
        background:'#318415'
    }
};

const noButtonStyle = {
    borderRadius: '5px',
    background: '#888',
    color:'#fff',
    width:'110px',
    '&:hover': {
        background:'rgba(0,0,0,0.3)'
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const PurchaseCreditPointsDialog = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [quantity, setQuantity] = React.useState(50);
    const [amount, setAmount] = React.useState(50*5);

    useEffect(() => {
        if(props.captureSelectedOrganization && 
            props.captureSelectedOrganization.organization && 
            props.captureSelectedOrganization.organization.is_subscribed){
            setAmount(50*5);
        }
        else{
            setAmount(50*5);
        }
    }, []);

    const increment = (e) => {
        let count = quantity + 50;
        calculateAmount(count);
        setQuantity(count);
    }
    
    const decrement = (e) => {
        if(quantity > 50){
            let count = quantity - 50;
            calculateAmount(count);
            setQuantity(count);
        }
    }

    const calculateAmount = (count) => {
        if(props.captureSelectedOrganization && 
            props.captureSelectedOrganization.organization && 
            props.captureSelectedOrganization.organization.is_subscribed){
                setAmount(count*5);
            }
            else{
                setAmount(count*5);
            }
    }

    const onPurchaseClick = () => {
        const organization_id = props.captureSelectedOrganization.organization.id;

        purchaseScanPointsApi(organization_id, quantity)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response createCheckoutSessionApi: ", res);
            window.location.href = res.url
        })
        .catch((e) => {
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'error'
                });
                return false;
            }
        });
    }

    return (
        <BootstrapDialog
            onClose={props.handleClosePurchaseDialog}
            aria-labelledby="customized-dialog-title"
            open={props.openPurchaseDialog}
            fullWidth
        >
            <DialogTitle sx={{ m: 0, padding: '8px 16px', background:'#f7f7f7', color:'#4a6568' }}>
                Purchase Scan Points
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={props.handleClosePurchaseDialog}
            sx={{
                position: 'absolute',
                right: 8,
                top: 4,
                color:'#4a6568'
            }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers sx={{display:'flex', justifyContent:'center'}}>
                <PurchaseCardComponent amount={amount}
                    quantity={quantity}
                    onPurchaseClick={onPurchaseClick}
                    fromPurchaseComponent={true}
                    increment={increment}
                    decrement={decrement}/>
            </DialogContent>

            <DialogActions sx={{ m: 0, padding: '8px 24px' }}>
                <Button 
                    color='secondary'
                    variant='outlined'
                    style={{marginRight:'16px', minWidth:'110px'}}
                    onClick={props.handleClosePurchaseDialog}>
                    Cancel
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization
});

export default connect(mapStateToProps)(PurchaseCreditPointsDialog);