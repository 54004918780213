import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { loadState, saveState } from './localStorage'
import configureStore from './store/configureStore';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import ReactGA from "react-ga4";
import {isDebug, consoleToLog} from './util/AppUtil';
import { generateRandomId, encryptReduxState, decryptReduxState } from './util/AppUtil';


const openpgp = require('openpgp');
const persistedState = await loadState();
const store = configureStore(persistedState);

// const randomId = generateRandomId();
// console.log('randomID', randomId);

// const obj = await openpgp.generateKey({
//   type: 'ecc', 
//   curve: 'ed25519', 
//   userIDs: [{ user_id: '25' }], 
//   passphrase: process.env.REACT_APP_ENC_TEXT_PASSPHRASE, 
//   format: 'armored' 
// });

// console.log('privatekey********', obj.privateKey);
// console.log('publickey*********', obj.publicKey);
// console.log('encprivatekey********', window.btoa(obj.privateKey));
// console.log('encpublickey*********', window.btoa(obj.publicKey));

store.subscribe(async() => {
  const obj = {
      drawerState: store.getState().drawerState,
      captureOrganizations: store.getState().captureOrganizations,
      captureSelectedOrganizationInfo: store.getState().captureSelectedOrganizationInfo,
      captureZohoToken: store.getState().captureZohoToken,
      captureZohoTokenList: store.getState().captureZohoTokenList
  }

  const serializedObject = JSON.stringify(obj);
  const encryptedData = await encryptReduxState(openpgp, serializedObject);
  saveState(window.btoa(encryptedData));
  // saveState({
  //     captureAuth: store.getState().captureAuth,
  //     drawerState: store.getState().drawerState,
  //     captureOrganizations: store.getState().captureOrganizations,
  //     captureSelectedOrganizationInfo: store.getState().captureSelectedOrganizationInfo,
  //     captureZohoToken: store.getState().captureZohoToken
  // });
});

//google analytics
const initGA4 = () => {
  consoleToLog("***init GA4")
  ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
}

if (!isDebug()) {
  initGA4();
}

//decryptText();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
  </Provider>
);
