import { consoleToLog } from "./AppUtil";

const globalInterval = () => {
    if(!window.originalSetInterval){
        window.originalSetInterval = window.setInterval;
    }
    if(!window.originalClearInterval){
        window.originalClearInterval = window.clearInterval;
    }
    window.activeIntervals = [];
    
    window.setInterval = function (func, delay)
    {
        consoleToLog("Into global interval")
        let intervalId;
        if(func && delay){
            intervalId=window.originalSetInterval(func,delay);
            window.activeIntervals.push(intervalId);
        }
        return intervalId;
    };
    window.clearInterval = function (intervalId)
    {
        // JQuery sometimes hands in true which doesn't count
        if(intervalId !== true){
            window.activeIntervals = window.activeIntervals.filter(id => id !== intervalId); 
        }
        return window.originalClearInterval(intervalId);
    };
}


export {
    globalInterval
}