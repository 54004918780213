export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_ZOHO_TOKEN_OBJ':
            return action.zoho_token_obj;
        
        case 'CLEAR_ZOHO_TOKEN_OBJ':
            return {};  
            
        default:
            return state;
    }
}