import { consoleToLog } from '../util/AppUtil';
import {getAxios} from './axiosUtil';

//get tally vendor
export const getTallyVendorsApi = (base, organization_id, company_id, company_name, vendor_gst_number, type, captured_vendor_name, isPurchase) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/company/${company_id}/getVendors?company_name=${company_name}&vendor_gst_number=${vendor_gst_number}&type=${type}&captured_vendor_name=${captured_vendor_name}&base=${base}&isPurchase=${isPurchase}`);
}


//get tally vendor
export const getTallyCompanyDetailsApi = (tally_base, organization_id, company_id, company_name, invoice_type, ledger_type) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/company/${company_id}/get_loaded_company_details?company_name=${company_name}&base=${tally_base}&invoice_type=${invoice_type}&ledger_type=${ledger_type}`);
}

export const getTallyActiveCompanyApi = (tally_base) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/get_active_companies?base=${tally_base}`);
}

export const getVendorsFromText = (base,company_name,text, isPurchase) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/getVendorsByText?company_name=${company_name}&text=${text}&base=${base}&isPurchase=${isPurchase}`);
}

export const getItemsFromText=(base, company_name,text) => {
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/getItemByText?company_name=${company_name}&text=${text}&base=${base}`);
}

export const syncInvoiceToTallyApi = (xml, base)=>{
    console.log("called sync invoice api");
    let data = {
        xml,
        base
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/push_invoice_tally`, data);
} 

export const createJournalForRCM = (xml, base)=>{
    console.log("called createJournalForRCM api");
    let data = {
        xml,
        base
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/create_rcm_journal`, data);
}

export const createJournalForITCApi = (xml, base)=>{
    console.log("called createJournalForITC api");
    let data = {
        xml,
        base
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/create_itc_journal`, data);
}

export const checkInvoiceExistApi=(organization_id, guid, invoice_number)=>{
    const param = {
        params: {
            invoice_number,
            guid
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/check_invoice_exist`, param);
}

export const tallyAppConnectionCheckApi = (base) => {
    const param = {
        params: {
            base
        }
    }
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/connectToTally`, param);
}

export const createCurrencyInTallyApi=(tally_base_url, symbolBase, storedCurrencyObj, company_name)=>{
    let data = {
        tally_base_url,
        symbolBase,
        storedCurrencyObj,
        company_name
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/create_currency_in_tally`, data);
}

export const getLedgersForRecordPaymentApi=(organization_id, company_id)=>{
    return getAxios().get(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/company/${company_id}/get_company_ledgers`);
}

export const createRecordPaymentApiForTally = (xml, base) => {
    console.log("called createRecordPaymentApi");
    let data = {
        xml,
        base
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/create_record_payment_tally`, data);
}

export const updateInvoiceObjAfterRecordPaymentApi=(organization_id, invoice_id, invoice_json, invoice_status)=>{
    let data = {
        invoice_json, invoice_status
    }
    return getAxios().put(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/invoice/${invoice_id}/update_after_record_payment`, data);
}

export const createRcmLedgerAutoApi = (organization_id, base, ledger_name, gst_duty_head, company_name, company_id) => {
    let data = {
        base,
        ledger_name,
        gst_duty_head,
        company_name,
        company_id
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/create_rcm_ledgers`, data);
}

export const createRcmLedgersManually=(organization_id, company_id, ledger_list)=>{
    let data = {
        company_id, 
        ledger_list
    }
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/create_rcm_ledgers_manually_and_map`, data);
}

export const createTallyTaxLedgerApi = (organization_id, base, ledger_name, gst_duty_head, company_name) => {
    let data = {
        base,
        ledger_name,
        gst_duty_head,
        company_name
    }
    
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_ELECTRON_BASE_URL}/organization/${organization_id}/create_tax_ledger`, data);
}

export const mapTaxLedgersApi = (ledgers, type, companyId, organization_id) => {
    let data = {
        type,
        ledgers
    }
    
    return getAxios().post(`${process.env.REACT_APP_CAPTURE_APP_BASE_URL}/organization/${organization_id}/company/${companyId}/add_tax_ledger`, data);
}