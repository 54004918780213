import { decryptReduxState } from "./util/AppUtil";
const openpgp = require('openpgp');

export const loadState = async() => {
    try{
        const serializedState = localStorage.getItem('organizationDetails');
        let decryptedState = null;
        if(serializedState) {
            const parsedEncodeSrting = JSON.parse(serializedState);
            decryptedState = await decryptReduxState(openpgp, window.atob(parsedEncodeSrting));
        }
        if(decryptedState === null){
            return undefined;
        }
        else {
            return decryptedState;
        }
    }
    catch(e) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('organizationDetails', serializedState);
    }catch(e) {

    }
}