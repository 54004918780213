import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
            light: '#F8F9FA', //bg color
            contrastText: '#fff',
        },
        secondary: {
            main: '#202124',
            light: '#5F6368', //light gray for subtitle
            contrastText: '#fff',
        }
    },
    typography:{
        fontFamily: 'Ubuntu',
        button: {
            textTransform: 'none'
        },
        body1: {
            fontSize: 15,
        },
        body2: {
            fontSize: 13,
            fontWeight: 400
        },
        subtitle1: {
            fontSize: 13,
        },
        subtitle2: {
        
        }
    },
});

export default theme;
