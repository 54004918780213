import React, {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import theme from '../theme';
import { connect } from 'react-redux';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import {logout} from '../actions/captureAuth';
import {clearOrgs} from '../actions/captureOrganizations';
import {setClearOrgState} from '../actions/captureSelectedOrganization';
import { logoutApi } from '../services/authAppService';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setSelectedOrg, fetchUserDetails } from '../actions/captureSelectedOrganization';
import { Divider, Grid, InputLabel } from '@mui/material';
import { setLoading } from '../actions/loading';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import {clearZohoTokenObj} from '../actions/captureZohoToken';
import { useIsMount } from './useIsMount';
import {AccessLevel, ERPSoftwares, getErpIconDependOnConnection, getErpSoftwareName, getStatusColor, isErpConnected, shouldDisable} from '../util/AppUtil';
import { useLocation } from 'react-router-dom';
import {trackGAEvent, capitalizeFirstLetterOfEachWord, consoleToLog} from '../util/AppUtil';
import CircleIcon from '@mui/icons-material/Circle';
import {Popover, ListItem} from '@mui/material';
import { connectToZoho } from '../util/AppUtil';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoopIcon from '@mui/icons-material/Loop';
import { callTallyConnectionApiFromUtil, clearIntervalFromUtil, createIntervalIfNoActiveInterval } from '../util/TallyUtil';
import { enqueueSnackbar } from 'notistack';
import {connectToERPSoftware} from '../util/AppUtil';

const pages = [
        {name:'Purchases', value: 'purchases'}, 
        {name: 'Companies', value: 'companies'}, 
        {name: 'Organization Settings', value: 'settings'}
    ];

const settings = [
        {name: 'My Profile', value: 'profile'}, 
        {name: 'My Organization', value: 'organization'}, 
        {name: 'Logout', value:'logout'}
    ];

const creditPointsStyle = {
    color:'#1A73E8', 
    fontWeight:600, 
    fontSize:'20px',
    cursor:'pointer',
    textAlign:"center",
    '&:hover': {
        textDecoration:'underline',
        color:'#007bfb'
    }
}

const flexStyle = {
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'16px',
    color:'#212121'
}

const buttonPlusIcon = {
    marginRight:'4px', 
    fontSize:'1.2rem', 
    display:'flex',
    alignItems:'center',
    marginTop:'2px',
    marginLeft: "2px",
    color: 'white'
};

const Header = (props) => {
    const navigate = useNavigate();
    const isMount = useIsMount();
    const location = useLocation();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElCreate, setAnchorElCreate] = React.useState(null);
    const [showConnectedErpPopover, setShowConnectedErpPopover] = React.useState(null);
    const [showNotConnectedErpPopover, setShowNotConnectedErpPopover] = React.useState(null);

    const { access_level } = props.captureSelectedOrganization || {};
    const accessLevelAdmin = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.ADMIN);

    const zohoTokenPresent = props.user.tokens && props.user.tokens.length > 0 && props.user.tokens[0]?.tk_type === 'zoho' ? true : false;
    const selectedErpSoftwareList = props.captureSelectedOrganization.organization?.selected_erp_softwares || [];
    
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onNavItemClick = (e, value) => {
        e.preventDefault();
        navigate(`/${value}`);
        props.setSelectedDrawerState(value);
        trackGAEvent(props.captureSelectedOrganization.organization.name, `${capitalizeFirstLetterOfEachWord(value)} tab clicked`, `${props.user.firstname} ${props.user.lastname}`);
    }

    const onMyProfileClick = (e, value) => {
        e.preventDefault();
        if(value === 'logout') {
            callLogoutApi();
        } else {
            navigate('/profile')
            props.setSelectedDrawerState(value);
        }
        trackGAEvent(props.captureSelectedOrganization.organization.name, `${capitalizeFirstLetterOfEachWord(value)} clicked`, `${props.user.firstname} ${props.user.lastname}`);
        handleCloseUserMenu();
    }

    const handleOpenCreateMenu = (event) => {
        setAnchorElCreate(event.currentTarget);
    }

    const handleCloseCreateMenu = () => {
        setAnchorElCreate(null);
    }

    const callLogoutApi = () => {
        logoutApi()
            .then((response) => {
                const res = response.data;
                consoleToLog('Response logoutApi: ', res);
                props.setLoading(true);
                props.clearOrgs();
                props.setClearOrgState();
                props.clearZohoTokenObj();
                props.logout();
                localStorage.clear();
                window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=capture`, '_self');
            })
            .catch((e) => {
                consoleToLog('Error logoutApi: ', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })

    };

    const onOrgChange = (e) => {
        const selected_org = e.target.value;
        props.setSelectedOrg(selected_org);
        trackGAEvent(selected_org.organization.name, `Changed Organization to ${selected_org.organization.name}`, `${props.user.firstname} ${props.user.lastname} `);

        props.fetchUserDetails(selected_org.organization.id, props.drawerState);
    };

    const onAddNewCompanyClick = () => {
        props.handleOpenAddCompanyDrawer();
        trackGAEvent(props.captureSelectedOrganization.organization.name, `Add Company clicked`, `${props.user.firstname} ${props.user.lastname} `);
        handleCloseCreateMenu();
    }

    const onAddNewBillClick = () => {
        props.handleOpenAddBillDrawer();
        trackGAEvent(props.captureSelectedOrganization.organization.name, `Add New Bill clicked`, `${props.user.firstname} ${props.user.lastname} `);
        handleCloseCreateMenu();
    }

    const onCreditPointClick = () => {
        trackGAEvent(props.captureSelectedOrganization.organization.name, `Scan points dialog opened`, `${props.user.firstname} ${props.user.lastname} `);
        props.handleOpenPurchaseDialog();
    }

    const showAllOrgErpSoftwares=()=>{
        let image=getErpIconDependOnConnection(selectedErpSoftwareList, zohoTokenPresent, props.isTallyConnected);

        return (
            <div 
                onMouseEnter={(e) => {
                    e.stopPropagation();
                    setShowConnectedErpPopover(e.currentTarget)
                }}
                onMouseLeave={() => setShowConnectedErpPopover(null)}>
                <Button style={{minWidth:'20px'}}>
                    <img alt="connected/disconnected" src={image} className='image_styles' />
                </Button>
                {showErpSoftwares()}
            </div>)
    }

    const handleCloseConnectedSoftwarePopover = () => {
        setShowConnectedErpPopover(null);
    }


    const onAddErpSoftwareButton = () => {
        connectToERPSoftware(navigate, props)
    }

    const showErpSoftwares=()=>{

        return (<Popover
                id='connected-erp-popover'
                open={Boolean(showConnectedErpPopover)}
                anchorEl={showConnectedErpPopover}
                onClose={handleCloseConnectedSoftwarePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                sx={{
                    '& .MuiPopover-paper' : {
                        padding:'4px 8px'
                    }
                }}
            >   
            {selectedErpSoftwareList && selectedErpSoftwareList.length > 0 ? selectedErpSoftwareList.map((erp)=>{
                const name=getErpSoftwareName(props.erpSoftwareList, erp);
                return(
                    <ListItem sx={flexStyle}>
                    <Typography>
                        {name}
                    </Typography>
                    { 
                        isErpConnected(erp, zohoTokenPresent, props.isTallyConnected) && <Typography
                        sx={{
                            padding:'2px 8px 3px 8px',
                            borderRadius: '3px',
                            color:'#fff',
                            fontStyle: "bold",
                            fontSize:"11px",
                            background: '#1fcd6d',
                        }}>
                        Connected
                    </Typography>}
                    {!isErpConnected(erp, zohoTokenPresent, props.isTallyConnected) && <Button size='small'
                        className='button_styles'
                        sx={{
                            height: "30px"
                        }}
                        onClick={() => onConnectToERPSoftwareClick(erp)}
                        variant='contained' color='secondary'>
                        Connect 
                        <ArrowForwardIcon fontSize='small' sx={buttonPlusIcon}/>
                    </Button>}
                    {erp === ERPSoftwares.TALLY 
                        && selectedErpSoftwareList.includes(ERPSoftwares.TALLY)&&
                        !isErpConnected(erp, zohoTokenPresent, props.isTallyConnected) &&
                        <Button 
                        onClick={checkTallyAppConnection}
                        style={{minWidth:'20px', color:'#212121'}}
                    >
                            <LoopIcon fontSize='small'/>
                    </Button>}
                </ListItem>
                )
            })
            :
            <ListItem sx={flexStyle}>
                <Typography>
                    Add ERP Software
                </Typography>
                <Button variant='contained' color='secondary'
                    size='small'
                    className='button_styles'
                    sx={{
                        height: "30px"
                    }}
                    onClick={onAddErpSoftwareButton}
                >
                        Add
                        <ArrowForwardIcon fontSize='small' sx={buttonPlusIcon}/>
                </Button>
            </ListItem>
        }
            
        </Popover>)
        
    }

    const onConnectToERPSoftwareClick = (value) => {
        if(value === ERPSoftwares.ZOHO_BOOKS) {
            connectToZoho()
        } else {
            props.handleAddTallyInfoDrawerOpen();
        }
    }

    const checkTallyAppConnection = async() => {
        if(props.user?.tally_host && props.user?.tally_port){
            let base=props.user?.tally_host+":"+props.user?.tally_port;
            consoleToLog("base", base)
            base=window.btoa(base).toString('base64');
            try{
                let response=await callTallyConnectionApiFromUtil(base);
                response = response.data;
                consoleToLog("Header: check tally connection: ", response)
                consoleToLog("Header Response tallyAppConnectionCheckApi: ", response);    
                if(response && response.isTallytConnected){
                    props.updateTallyConnected(true)
                    createIntervalIfNoActiveInterval(base, checkTallyAppConnection);
                }
                else{
                    props.updateTallyConnected(false)
                }
            }
            catch(err){
                consoleToLog("Error tallyAppConnectionCheckApi: ", err);
                consoleToLog("calling interval clear");
                enqueueSnackbar("Unable to connect to tally. Kindly check if connector app opened or configure tally host and port from connector app.", {variant:'error'});
                props.updateTallyConnected(false)
                clearIntervalFromUtil();
            }
        }
        else{
            enqueueSnackbar("Unable to connect to tally. Kindly configure tally host and port from connector app.", {variant:'error'});
        }
    }

return (
    <AppBar position="sticky" sx={{boxShadow:'none', borderBottom:'1px solid #DADCE0', top:0}}>
        <Box className={'container_margin'}>
            <Toolbar disableGutters>
                    <div style={{cursor:'pointer'}} onClick={() => navigate('/purchases')}>
                        <img src='/images/eprocessify-logo-svg.svg' width='120px' style={{marginLeft: "-10px"}}/>
                    </div>

                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                        {pages.map((page) => (
                            <MenuItem key={page.value} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page.name}</Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft:'2rem' }}>
                        {pages.map((page) => (
                        <Button
                            key={page.value}
                            onClick={(e) => onNavItemClick(e, page.value)}
                            sx={{color: location.pathname === `/${page.value}` ? theme.palette.secondary.main : theme.palette.secondary.light, display: 'block' }}
                        >
                            {page.name}
                        </Button>
                        ))}
                    </Box> */}

                    <Box display='flex' justifyContent='flex-end' alignItems='center' gap={2} sx={{ flexGrow: 1}}>
                        <Grid item>
                            <Grid item container alignItems='center' spacing={1}>
                                <Grid item>
                                    {showAllOrgErpSoftwares()}
                                </Grid>
                                <Grid item>
                                <Divider orientation='vertical' sx={{bgcolor:'#DCDCDC', height:'40px'}}/>
                                </Grid>

                                <Grid item>
                                    <Tooltip title='Purchase Scan Points' arrow>
                                        <Typography onClick={() => {
                                                    if(!accessLevelAdmin) {
                                                        onCreditPointClick();
                                                    }
                                                }} 
                                                sx={creditPointsStyle}> 
                                            {props.captureSelectedOrganization?.organization?.credit_points}
                                        </Typography>
                                    </Tooltip>
                                    <Typography sx={{color:'#333333', fontSize:'14px', fontWeight:500, marginTop:'-5px'}}> 
                                        Scan Points
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select variant='outlined' color='secondary'
                                    value={props.captureOrganizations.find(org => org.organization.name === props.captureSelectedOrganization.organization?.name)}
                                    onChange={onOrgChange}
                                    sx={{
                                        '& .MuiSelect-outlined': {
                                            color:'#555',
                                            border:'1px solid #ccc'
                                        },
                                        '& .Mui-focused' : {
                                            border:'1px solid #ccc'
                                        }
                                    }}
                                    
                                >
                                    {props.captureOrganizations && props.captureOrganizations.length > 0 &&
                                    props.captureOrganizations.map((org) => {
                                        return  <MenuItem key={org.id} value={org}>{org.organization.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        <Grid item>
                            <Button onClick={handleOpenCreateMenu} 
                                startIcon={<AddOutlinedIcon/>} className='newbtn__style'> 
                                New
                            </Button>
                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElCreate}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElCreate)}
                            onClose={handleCloseCreateMenu}
                            >
                                <MenuItem onClick={onAddNewBillClick}>
                                    <ReceiptLongOutlinedIcon className='createheader_incons'/>
                                    <Typography 
                                        variant='body1'
                                        textAlign="center">New Bill</Typography>
                                </MenuItem>
                                {!accessLevelAdmin && <MenuItem onClick={onAddNewCompanyClick}>
                                    <ApartmentOutlinedIcon className='createheader_incons'/>
                                    <Typography 
                                        variant='body1'
                                        textAlign="center">New Company</Typography>
                                </MenuItem>}
                            </Menu>
                        </Grid>

                        <Grid item style={{marginRight: "-4px"}}>
                            <Tooltip title="Profile">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{
                                        width:'30px', 
                                        height:'30px', 
                                        fontSize:'13px', 
                                        background: '#333333', 
                                        display:'flex', 
                                        alignItems:'center'
                                    }}>
                                    {props.user.firstname?.charAt(0) + props.user.lastname?.trim()?.charAt(0)}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            {settings.map((setting) => (
                                <MenuItem key={setting.value} onClick={(e) => onMyProfileClick(e, setting.value)}>
                                    <Typography 
                                        variant='body1'
                                        textAlign="center">{setting.name}</Typography>
                                </MenuItem>
                            ))}
                            </Menu>
                        </Grid>
                    </Box>
        </Toolbar>
    </Box>
    </AppBar>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    drawerState: state.drawerState.setDrawerState,
    user: state.captureSelectedOrganizationInfo.user,
    captureOrganizations: state.captureOrganizations,
    erpSoftwareList: state.captureSelectedOrganizationInfo.erpSoftwareList
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState : (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setSelectedOrg: (organization) => dispatch(setSelectedOrg(organization)),
    fetchUserDetails: (organization_id, drawerState) =>  dispatch(fetchUserDetails(organization_id, drawerState)),

    logout: () => dispatch(logout()),
    clearOrgs: () => dispatch(clearOrgs()),
    setClearOrgState: () => dispatch(setClearOrgState()),
    clearZohoTokenObj: () => dispatch(clearZohoTokenObj())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);