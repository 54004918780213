import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Layout from "./Layout";
import AddBillDrawer from "../components/AddBillDrawer";

const DashboardPage = lazy(
    () => import("../pages/DashboardPage")
);

const RootPage = lazy(
    () => import("../pages/RootPage")
);

const InvoiceComponent = lazy(
    () => import("../components/DashboardComponent")
);

const SalesComponent = lazy(
    () => import("../components/SalesComponent")
);

const Companies = lazy(
    () => import("../components/CompaniesComponent")
);

const Settings = lazy(
    () => import("../components/OrganizationSettings")
);

const Profile = lazy(
    () => import("../components/MyProfileComponent")
);

const VerifyZohoPage = lazy(
    () => import("../pages/VerifyZohoPage")
);

const StripePaymentSucess = lazy(
    () => import("../pages/StripePaymentSucess")
);

const OnboardingPage = lazy(
    () => import("../pages/OnboardingPage")
);

const NotFoundPage = lazy(
    () => import("../pages/NotFoundPage")
);




export default function PrivateRoutes() {
    return {
        element: <Layout />,
        children: [
            // { path: "/", element: <DashboardPage /> },
            { path: "/", element: <RootPage /> },
            { path: "/purchases", element: <InvoiceComponent /> },
            { path: "/sales", element: <SalesComponent /> },
            { path: "/companies", element: <Companies /> },
            { path: "/settings", element: <Settings /> },
            { path: "/profile", element: <Profile /> },
            { path: "/verify_zoho", element: <VerifyZohoPage /> },
            { path: "/payment/success", element: <StripePaymentSucess /> },
            { path: "/onboarding", element: <OnboardingPage /> },
            { path: "*", element: <NotFoundPage /> },
        ],

    };
}