import React, {useState, useEffect} from 'react';
import DrawerComponent from './DrawerComponent';
import { getConnectorAppVersionApi } from '../services/captureAppService';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';
import {Grid, Typography, Button, List, ListItem, ListItemText} from '@mui/material';

const downloadLink = {
    color: '#20a8e0',
    cursor: 'pointer',
    fontSize:'14px',
    margin:"0px 1px 0px 8px",
    textAlign:"center",
    '&:hover': {
        textDecoration:'underline'
    }
}

const list_item = {
    display: "list-item", 
    padding: "4px 16px" 
}

const centerText={
   textAlign: "center"
}


const AddTallyConnectionDrawer = (props) => {

    const organization_id = props.captureSelectedOrganization.organization.id;

    const [showTallyAppDownloadLink, setShowTallyAppDownloadLink] = useState(undefined);

    useEffect(() => {
        if(props.addTallyInfoDrawer) {
            getConnectorAppDownloadLink();
        }
    }, []);

    const getConnectorAppDownloadLink = () => {
        getConnectorAppVersionApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getConnectorAppVersionApi: ', res);
                setShowTallyAppDownloadLink(res.versions);
                
            })
            .catch((e) => {
                consoleToLog('Error getConnectorAppVersionApi', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const downloadConnectorApp = () => {
        document.getElementById('tally-connector-app').href = showTallyAppDownloadLink?.latest_version_url;
        document.getElementById('tally-connector-app').click();
    }

    return (
        <DrawerComponent title='Add Tally Info'
            openDrawer={props.addTallyInfoDrawer}
            handleCloseDrawer={props.handleAddTallyInfoDrawerClose}>
                
                <Grid item md={12} style={{padding:'16px'}}>
                        <Typography sx={centerText}>
                            Please Download Tally Connector App from below link.
                        </Typography>
                        {showTallyAppDownloadLink?.latest_version_url &&
                        <Typography id='tally-connector-app' 
                            sx={downloadLink}
                            onClick={downloadConnectorApp}>
                            Download
                        </Typography>}
                        <Typography style={{fontWeight: "bold"}}>
                            Steps for Tally:
                        </Typography>
                        <List sx={{ listStyle: "decimal", pl: 4 }}>
                            <ListItem sx={list_item}>
                                Open Tally
                            </ListItem>
                            <ListItem sx={list_item}>
                                Open client/server configuration dialog by navigating to Help => Settings => Connectivity
                            </ListItem>
                            <ListItem sx={list_item}>
                                In Client/Server configuration, set following values:
                                <ListItemText primary="- TallyPrime act as - Both"></ListItemText>
                                <ListItemText primary="- Enable ODBC - Yes"></ListItemText>
                                <ListItemText primary="- Port - 9000"></ListItemText>
                            </ListItem>
                        </List>
                        <Typography style={{fontWeight: "bold"}}>
                            Steps for Tally Connector:
                        </Typography>
                        <List sx={{ listStyle: "decimal", pl: 4 }}>
                            <ListItem sx={list_item}>
                                Download eProcessify Tally Connector App.
                            </ListItem>
                            <ListItem sx={list_item}>
                                Login with capture eProcessify credentials
                            </ListItem>
                            <ListItem sx={list_item}>
                            Navigate to Settings tab and  set the Tally host and port.
                            <ListItemText primary="Host- IP address of the machine where you change the connectivity configuration of the Tally software. If it is local machine, use http://localhost"></ListItemText>
                            <ListItemText primary="Port - Port number mentioned in Tally Software connectivity configuration"></ListItemText>
                            </ListItem>
                            <ListItem sx={list_item}>
                                <ListItemText primary="a. Navigate to Add companies tab. It shows all companies on disk."></ListItemText>
                                <ListItemText primary="b. Subscribe to companies you wanted to work on. To subscribe the company, you will need to load it on Tally."></ListItemText>
                            </ListItem>
                            <ListItem sx={list_item}>
                            Once companies are subscribed, they can be seen in My companies
                            </ListItem>
                            <ListItem sx={list_item}>
                            Now click Sync Master button to sync Tax Ledgers, Vendors, Account Head Ledgers, Round Off and Discount Ledgers into  eProcessify!
                            </ListItem>
                        </List>
                </Grid>

        </DrawerComponent>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    user: state.captureSelectedOrganizationInfo.user
});

export default connect(mapStateToProps)(AddTallyConnectionDrawer);