export const addOrUpdateZohoTokenToList = (zoho_token_obj) => ({
    type: 'ADD_UPDATE_ZOHO_TOKEN_LIST',
    zoho_token_obj
});

export const clearZohoTokenObj = () => ({
    type: 'CLEAR_ZOHO_TOKEN_LIST'
});


export const setZohoTokenList = (zoho_token_list) => ({
    type: 'UPDATE_ZOHO_TOKEN_LIST',
    zoho_token_list
});