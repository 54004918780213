import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { login } from '../actions/captureAuth';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../actions/loading';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { logoutApi } from '../services/authAppService';
import {logout} from '../actions/captureAuth';
import {clearOrgs} from '../actions/captureOrganizations';
import {setClearOrgState} from '../actions/captureSelectedOrganization';
import {clearZohoTokenObj} from '../actions/captureZohoToken';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { consoleToLog } from '../util/AppUtil';
import { fetchAccessToken } from '../util/AppUtil';
import { getOrganizationList } from '../actions/captureSelectedOrganization';

const RootPage = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const backUrlFromLayoutComponent = location.state?.back_url;
    //const logout = location.state?.logOut;
    

    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let first_login = url.searchParams.get("first_login");
        let logout = url.searchParams.get("logout");
        let back_url = url.searchParams.get("back_url");
        let goToRespectiveUrl = back_url ? back_url : backUrlFromLayoutComponent;

        if(logout === "true") {
            props.setLoading(true);
            props.clearOrgs();
            props.setClearOrgState();
            props.clearZohoTokenObj();
            props.logout();
            props.setSelectedDrawerState('');
            return window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=capture${back_url && back_url !== '' ? `&back_url=${back_url}` : ''}`, '_self')
                
        } else {
            const fetchToken = async () => {
                try {
                const data = await fetchAccessToken('capture');
                if(data) {
                    consoleToLog('Response authenticateApi', data);
                    const {access_token, redirect_url, invalid_service, scope} = data;
                    if(access_token) {
                        if(scope?.includes('capture')) {
                            props.setLoading(true);
                            if(first_login === "true") {
                                navigate('/onboarding');
                            } else {
                                props.getOrganizationList(props.captureSelectedOrganization, false, props.drawerState);
                                if(goToRespectiveUrl) {
                                    console.log('goToRespectiveUrl*******', goToRespectiveUrl);
                                    navigate(`${goToRespectiveUrl}`);
                                } else {
                                    navigate('/purchases');
                                }
                            }
                        } else {
                            window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/not-registered?service=capture`, '_self');
                        }
                    }  else {
                        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/login?service=capture`, '_self');
                    }
    
                } 
                } catch (error) {
                    consoleToLog("error fetchAccessToken", error);
                }
            };
        
            fetchToken();
        }
            
    }, [])

    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress size={28} color="secondary" style={{marginTop:"32px"}}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    isLoading: state.loading.isLoading,
    drawerState: state.drawerState.setDrawerState,
    user: state.captureSelectedOrganizationInfo.user
})

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading)),
    getOrganizationList: (selectedOrganization, fromOnboarding, drawerState) => dispatch(getOrganizationList(selectedOrganization, fromOnboarding, drawerState)),

    logout: () => dispatch(logout()),
    clearOrgs: () => dispatch(clearOrgs()),
    setClearOrgState: () => dispatch(setClearOrgState()),
    clearZohoTokenObj: () => dispatch(clearZohoTokenObj()),
    setSelectedDrawerState : (drawerState) => dispatch(setSelectedDrawerState(drawerState))
})

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);