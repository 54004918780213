import React from 'react';
import {Drawer, AppBar, Toolbar, Box, Typography, IconButton} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const DrawerComponent = (props) => {
    return (
        <Drawer anchor='right'
            open={props.openDrawer}
            onClose={props.handleCloseDrawer}>
            <Box sx={{ flexGrow: 1, width:'450px'}}>
                <AppBar position="static" 
                    elevation={0}
                    sx={{backgroundColor:'#f8f8f8', color:'#202020', height:'45px', borderBottom:'1px solid #ccc'}}>
                    <Toolbar disableGutters style={{padding:'0px 8px 0px 16px', minHeight:"40px"}}>
                        <Typography variant="body1" sx={{ flexGrow: 1}}>
                            {props.title}
                        </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={props.handleCloseDrawer}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <div>
                    {props.children}
                </div>
            </Box>
        </Drawer>
    );
}

export default DrawerComponent;