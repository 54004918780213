export default (state = [], action) => {
    switch (action.type) {
        case 'ADD_UPDATE_ZOHO_TOKEN_LIST':
            //TODO handle list
            if(state && state.length===0){
                state.push(action.zoho_token_obj);
            }
            if(state && state.length>0){
                let obj=action.zoho_token_obj;
                let isUpdated=false;
                for(let i=0;i<state.length;i++){
                    //update email
                    if(state[i].email===obj.email){
                        state[i] = obj;
                        isUpdated=true;
                    }
                }

                if(!isUpdated){
                    state.push(action.zoho_token_obj);
                }
            }
        return state;

        case 'UPDATE_ZOHO_TOKEN_LIST': 
            state=(action.zoho_token_list&& action.zoho_token_list.length>0) ? action.zoho_token_list : []
        return state;

        case 'CLEAR_ZOHO_TOKEN_LIST':
            return [];  
            
        default:
            return state;
    }
}