import {organizationListApi, getUserDetailsObjApi, organizationDetailsApi, getZohoUserDetail, getZohoOrganizationListApi} from '../services/captureAppService';
import { consoleToLog } from '../util/AppUtil';
import {setOrganizations} from './captureOrganizations';
import {setLoading} from './loading';
import {getRefereshedZohoTokensApi} from '../services/captureAppService';
import { setZohoTokenObj } from './captureZohoToken';
import {getZohoTokenObj} from '../util/AppUtil';
import { setSelectedDrawerState, SelectedDrawerState } from './drawer';
import {logout} from '../actions/captureAuth';
import {clearOrgs} from '../actions/captureOrganizations';
//import {setClearOrgState} from '../actions/captureSelectedOrganization';
import {clearZohoTokenObj} from '../actions/captureZohoToken';
import {getSupportedSoftwareListApi} from '../services/captureAppService';
import { addOrUpdateZohoTokenToList } from './captureZohoTokenList';

export const setSelectedOrganizationId = (organization_id) => ({
    type: 'SET_SELECTED_ORG_ID',
    organization_id
});

export const setSelectedOrg = (organization) => ({
    type: 'SET_SELECTED_ORG',
    organization
});

export const getOrganizationList = (selectedOrganization, fromOnboarding, drawerState) => {
    return (dispatch) => {
        organizationListApi()
            .then((response) => {
                const res = response.data;
                consoleToLog("Response OrganizationListApi ", res);
                dispatch(setOrganizations(res.organizations));

                let organization_id;

                if (Object.keys(selectedOrganization).length === 0) {
                
                    organization_id = res.organizations[0].organization?.id;
                    dispatch(setSelectedOrg(res.organizations[0]));
                    dispatch(getSupportedSoftwareList(organization_id));
                    if(fromOnboarding) {
                        dispatch(setLoading(false));
                    } else {
                        dispatch(fetchUserDetails(organization_id, drawerState));
                    }
                
                } else {

                    organization_id = selectedOrganization.organization.id;
                        //if found update its data
                    const updateSelectedOrg = res.organizations.find((org) => org.organization.id === organization_id);
                    if (updateSelectedOrg) {
                        dispatch(getSupportedSoftwareList(organization_id));
                        dispatch(setSelectedOrg(updateSelectedOrg));
                        dispatch(fetchUserDetails(organization_id, drawerState));
                    }
                }
                
            })
            .catch((e) => {
                consoleToLog('Error OrganizationListApi1', e);
                dispatch(setLoading(false));
                const { status } = e.response;
                consoleToLog('Error code', status);
                //if unauthorized error comes we will clear all data from redux and redirect to login
                // if (status === 401) {
                //     dispatch(logout());
                //     dispatch(clearOrgs());
                //     dispatch(setClearOrgState());
                //     dispatch(clearZohoTokenObj());
                //     window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=capture`, '_self');
                // }

                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    };
}

export const getSupportedSoftwareList = (organization_id) => {
    return (dispatch) => {
        getSupportedSoftwareListApi(organization_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getSupportedSoftwareListApi: ', res);

            dispatch(setSupportedSoftwareList(res.support_erp_softwares));

        })
        .catch((e) => {
            consoleToLog('Error getSupportedSoftwareListApi: ', e.response);
            dispatch(setLoading(false));
            if(e.response.data && e.response.data.message) {
                return;
            }
        });
    }

}

export const fetchUserDetails = (organization_id, drawerState) => {
    return (dispatch) => {
        getUserDetailsObjApi(organization_id)
            .then(async(response) => {
                const res = response.data;
                consoleToLog('Response getUserDetailsObjApi: ', res);

                dispatch(setUserObj(res.user));

                let tokenArr = res.user.tokens;
                let connectedToZoho = tokenArr.filter((token) => token.tk_type === 'zoho');

                if(connectedToZoho && connectedToZoho.length > 0) {
                    for(let i=0;i<connectedToZoho.length;i++){
                        consoleToLog("connectedToZoho token: ", connectedToZoho[i])
                        await dispatch(getRefereshedZohoToken(organization_id, connectedToZoho[i].tk_type, connectedToZoho[i].email, drawerState))
                    }
                } else {
                    dispatch(fetchOrgDetails(organization_id, drawerState))
                }
            })
            .catch((e) => {
                consoleToLog('Error getUserDetailsObjApi: ', e.response);
                dispatch(setLoading(false));
                dispatch(fetchOrgDetails(organization_id, drawerState))
                if(e.response.data && e.response.data.message) {
                    return;
                }
            });
    }
}

const getRefereshedZohoToken = (organization_id, tk_type, email, drawerState) => {
    return (dispatch) => {
        getRefereshedZohoTokensApi(organization_id, tk_type, process.env.REACT_APP_ZOHO_REDIRECT_URL, email)
            .then(async(response) => {
                const res = response.data;
                consoleToLog('Response getRefereshedZohoTokensApi: ', res);

                dispatch(setZohoTokenObj(getZohoTokenObj(res)))
                dispatch(fetchOrgDetails(organization_id, drawerState));


                const userDetail=await getZohoUserDetail(organization_id, res.result?.access_token);
                consoleToLog("**Response zohouserDetail on login: ", userDetail.data);

                //get zoho org list
                let zohoOrgList=await getZohoOrganizationListApi(organization_id, 'zoho', res.result?.access_token, res.result?.api_domain)
                zohoOrgList=(zohoOrgList.data.zoho_orgs && zohoOrgList.data.zoho_orgs.length>0) ? zohoOrgList.data.zoho_orgs.map((org)=>{return {name: org.name, organization_id: org.organization_id}}): []
                consoleToLog("Response zohoOrgList: ", zohoOrgList);

                //multiple zoho token
                res.email=userDetail?.data?.userDetail?.Email;
                res.zohoOrgList=zohoOrgList;
                dispatch(addOrUpdateZohoTokenToList(getZohoTokenObj(res)));
            })
            .catch((e) => {
                consoleToLog('Error getRefereshedZohoTokensApi: ', e.response);
                dispatch(setLoading(false));
                if(e.response.data && e.response.data.message) {
                    return;
                }
            });
    }
}

//set members list of selected org
export const setOrgMembers = (members) => ({
    type: 'SET_ORG_MEMBERS',
    members
});

export const fetchOrgDetails = (organization_id, drawerState) => {
    return(dispatch) => {
        organizationDetailsApi(organization_id)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response OrganizationDetailsApi: ', res);

                    //set members & labels of selected organization in redux
                    dispatch(setOrgMembers(res.members));
                    dispatch(setLoading(false));
                    if(drawerState !== '') {
                        dispatch(setSelectedDrawerState(drawerState))
                    } else {
                        //navObj('/purchases', {replace: true});
                        dispatch(setSelectedDrawerState(SelectedDrawerState.SHOW_PURCHASES))
                    }
                })
                .catch((e) => {
                    consoleToLog("OrganizationDetailsApi error", e);
                    dispatch(setLoading(false));
                    if (e.response.data && e.response.data.message) {
                        return false;
                    }
                });
    }
}

export const setUserObj = (user_obj) => ({
    type: 'SET_USER_OBJ',
    user_obj
});

export const setClearOrgState = () => ({
    type: 'SET_CLEAR_ORG_STATE',
});

//add member to org
export const addOrgMember = (member) => ({
    type: 'ADD_ORG_MEMBER',
    member
});

//update member (role changed)
export const updateMemberRole = (organization_member_id, access_level) => ({
    type: 'UPDATE_ORG_MEMBER_ROLE',
    organization_member_id,
    access_level
});

//Remove Organization Member
export const removeOrgMember = (organization_member_id) => ({
    type: 'REMOVE_ORG_MEMBER',
    organization_member_id
});

export const setSelectedOrgOnboarding = (onboarding) => ({
    type: 'SET_SELECTED_ORG_ONBOARDING',
    onboarding
});

export const updateSelectedOrg = (organization) => ({
    type: 'UPDATE_SELECTED_ORG',
    organization
});

export const updateUser = (user) => ({
    type: 'UPDATE_USER',
    user
});

export const setSupportedSoftwareList = (software_list) => ({
    type: 'SET_SUPPORTED_SOFTWARE_LIST',
    software_list
})