import './App.css';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <>
    <SnackbarProvider maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      TransitionComponent={Slide}
      >
      <AppRouter />
    </SnackbarProvider>
    </>
  );
}

export default App;
