
export const setOrganizations = (organizations) => ({
    type: 'SET_ORGANIZATIONS',
    organizations
});

export const updateOrganization = (organization) => ({
    type: 'UPDATE_ORGANIZATION',
    organization
});

export const clearOrgs = () => ({
    type: 'CLEAR_ORGANIZATIONS'
})