import { tallyAppConnectionCheckApi } from "../services/tallyService"
import { consoleToLog } from "./AppUtil";
import { globalInterval } from "./intervalUtil";

const callTallyConnectionApiFromUtil=async(base)=>{
    const response = await tallyAppConnectionCheckApi(base);
    return response;
}

const createIntervalIfNoActiveInterval=(base, checkTallyAppConnection)=>{
    consoleToLog("window.activeIntervals", window.activeIntervals);
    if(!window.activeIntervals || window.activeIntervals.length===0){
        consoleToLog("Interval created!");
        globalInterval();
        const interval=window.setInterval(checkTallyAppConnection, 60000);
        console.log("created interval id: ", interval)
    }
}

const clearIntervalFromUtil=()=>{
    console.log("util window.activeIntervals: ", window.activeIntervals)
    if(window.activeIntervals && window.activeIntervals.length === 1){
        consoleToLog("Interval cleared!");
        window.clearInterval(window.activeIntervals[0]);
    }
}

var decodeHtmlEntity = function(str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  };

const extractVendorGSTIN=(option)=>{
    let gstin='';
    if(option?.PARTYGSTIN){
        gstin=option.PARTYGSTIN;
    }
    else if(option && Object.keys(option).length>0){
        gstin = option['LEDGSTREGDETAILS.LIST']?.GSTIN || "";
    }
    return gstin;
}

const extractVendorName = (option) => {
    if(option && Object.keys(option).length>0){
        let localOption = option['LANGUAGENAME.LIST']['NAME.LIST'].NAME;
        let name='';
        if(typeof localOption === 'object'){
            name=localOption[0];
        }
        else{
            name=localOption; 
        }
        return decodeHtmlEntity(name);
    }
    else return ""
}

const extractItemName = (option) => {
    //console.log("**Option: ", option);
    if(option && Object.keys(option).length>0){
        let localOption = option['LANGUAGENAME.LIST']['NAME.LIST'].NAME;
        let name='';
        if(typeof localOption === 'object'){
            name=localOption[0];
        }
        else{
            name=localOption; 
        }
        return name;
    }
    else return ""
}

const getItemObj = (index, lineItems) => {
    const itemObj=lineItems[index]?.full_item
    return itemObj || null;
}

const getAccountObj = (index, accountArr, lineItems) => {
    const acctObj = accountArr?.find((acct) => {
        return acct.id === lineItems[index]?.account_id;
    });
    
    return acctObj || null;
}

const capitallizeAccountType = (str) => {
    var i, frags = str?.split('_') || [];
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

const extractCostCenterObject = (option) => {
    let costCenterObj=null;
    if(option && Object.keys(option).length>0){
        costCenterObj={};
        let localOption = option['LANGUAGENAME.LIST']['NAME.LIST'].NAME;
        let name='';
        if(typeof localOption === 'object'){
            name=localOption[0];
        }
        else{
            name=localOption; 
        }

        name=decodeHtmlEntity(name);
        costCenterObj.name=name;
        costCenterObj.category=option.CATEGORY;
        return costCenterObj;
    }
    else return costCenterObj;
}

const extractRequiredHighlightsOne = (SummaryFields, type)=> {
    let localSF={};
    let gstType='';
    if(type === 'purchase'){
        gstType='VENDOR_GST_NUMBER'
    }
    else{
        gstType='RECEIVER_GST_NUMBER'
    }
    for(let i=0;i<SummaryFields.length;i++){
        if(SummaryFields[i].Type.Text === "VENDOR_NAME" && !localSF.vendor_name){
        localSF.vendor_name=SummaryFields[i].ValueDetection.Text;
        }
        else if(SummaryFields[i].Type.Text === "INVOICE_RECEIPT_ID" && !localSF.invoice_number){
        localSF.invoice_number=SummaryFields[i].ValueDetection.Text;
        }
        else if(SummaryFields[i].Type.Text === "INVOICE_RECEIPT_DATE" && !localSF.invoice_date){
        localSF.invoice_date=SummaryFields[i].ValueDetection.Text;
        }
        else if(SummaryFields[i].Type.Text === "TOTAL" && !localSF.amount){
        localSF.amount=SummaryFields[i].ValueDetection.Text;
        } 
        else if(SummaryFields[i].Type.Text === gstType && !localSF.amount){
            localSF.vendor_gst_number = SummaryFields[i].ValueDetection.Text;
        }
    }
    return localSF;
}

const extractTaxLedgerName = (option) => {
    if(option && Object.keys(option).length>0){
        let localOption = option['LANGUAGENAME.LIST']['NAME.LIST'].NAME;
        let name='';
        if(typeof localOption === 'object'){
            name=localOption[0];
        }
        else{
            name=localOption; 
        }
        return name;
    }
    else return ""
}

export {
    createIntervalIfNoActiveInterval,
    clearIntervalFromUtil,
    callTallyConnectionApiFromUtil,
    extractVendorName,
    decodeHtmlEntity,
    extractItemName,
    getItemObj,
    getAccountObj,
    capitallizeAccountType,
    extractCostCenterObject,
    extractRequiredHighlightsOne,
    extractVendorGSTIN,
    extractTaxLedgerName
}