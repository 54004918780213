import React from 'react';
import {Paper, Button, Typography, Grid} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { trackGAEvent } from '../util/AppUtil';
import { connect } from 'react-redux';

const headingStyle = {
    fontSize:'20px',
    color: '#007bfb',
    fontWeight: 600
}

const currencySymbol = {
    fontSize:'14px',
    marginTop:'16px',
    position:'relative',
    top:'-10px',
    fontWeight:600,
    marginRight:'4px'
}

const amountText = {
    fontSize:'25px',
    color:'#212121',
}

const incrementDecrementStyle = {
    minWidth: "30px",
    height:'35px',
    border:'1px solid #888',
    borderRadius:'190px',
    color:"#333"
}

const quantityText = {
    border:'1px solid #ccc',
    lineHeight:'32px',
    width:'60px',
    height:'35px',
    textAlign:'center',
    fontSize:'16px',
    borderRadius:'5px'
}

const subscribeButton = {
    background: '#60b91f',
    width:'180px',
    borderRadius:'20px',
    '&:hover': {
        background: '#559129'
    }
}

const PurchaseCardComponent = (props) => {

    const onCheckoutClick = () => {
        if(props.fromPurchaseComponent) {
            props.onPurchaseClick();
            trackGAEvent(props.captureSelectedOrganization.organization.name, `Purchase Scan PointS clicked`, `${props.user.firstname} ${props.user.lastname}`);
        } else {
            props.onCheckoutClick();
            trackGAEvent(props.captureSelectedOrganization.organization.name, `Purchase Companies clicked`, `${props.user.firstname} ${props.user.lastname}`);
        }
    }

    return (
        <div>
            <Paper variant='outlined' sx={{padding:"16px", width:'212px'}}>
                <Grid item>
                    <Typography sx={headingStyle}>
                        Premium Plan
                    </Typography>
                </Grid>

                <Grid item container 
                    alignItems='center'
                    sx={{marginTop:'8px'}}>
                        
                    <Grid item>
                        <Typography sx={currencySymbol}>
                            ₹
                        </Typography>
                    </Grid>
                    
                    <Grid item>
                        <Typography sx={amountText}>
                            {props.amount}
                        </Typography>
                    </Grid>

                    {!props.fromPurchaseComponent &&
                    <Grid item>
                        <Typography sx={{fontSize:'18px', margin:'0px 2px'}}>
                            /
                        </Typography>
                    </Grid>}
                    
                    {!props.fromPurchaseComponent &&
                    <Grid item>
                        <Typography sx={{fontSize:'13px', position:'relative', top:'3px'}}>
                            month
                        </Typography>
                    </Grid>
                    }
                </Grid>

                <Grid item sx={{marginTop:'8px'}}>
                    <Typography variant='body2' style={{marginBottom:'4px'}}>
                        Quantity
                    </Typography>
                    <Grid item container spacing={2} 
                        alignItems='center'
                        >
                            
                        <Grid item>
                            <Button 
                                onClick={() => props.decrement()}
                                sx={incrementDecrementStyle}>
                                <RemoveIcon fontSize='small'/>
                            </Button>
                        </Grid>
                        
                        <Grid item>
                            <Typography sx={quantityText}>
                                {props.quantity}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button 
                                onClick={() => props.increment()}
                                sx={incrementDecrementStyle}>
                                <AddIcon fontSize='small'/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{marginTop:'16px'}}>
                    <Button 
                        onClick={onCheckoutClick}
                        sx={subscribeButton}>
                        {props.fromPurchaseComponent ? 'Purchase' : 'Checkout'}
                    </Button>
                    
                </Grid>
            </Paper>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.captureSelectedOrganizationInfo.user,
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(PurchaseCardComponent);