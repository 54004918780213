import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";

// App component
const AppRouter = (props) => {
  // Combine and conditionally include routes based on authentication status

    const router = createBrowserRouter([
      PrivateRoute()
    ]);
    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
}

const mapStateToProps = (state) => ({
    accessToken: state.captureAuth,
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
});

export default connect(mapStateToProps)(AppRouter);