import React, { useEffect, useState } from 'react';
import DrawerComponent from './DrawerComponent';
import { Container, Grid, Button, CircularProgress, Typography, Autocomplete, TextField, FormControl, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import {connect} from 'react-redux';
import { FileExtenions, consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { createInvoiceRecordApi, getLoggedInUserCompaniesApi, uploadAttachmentApi } from '../services/captureAppService';
import { checkOrgCompanyValidation } from '../util/AppUtil';
import {useIsMount} from './useIsMount';
import jsPDF from 'jspdf';
import { shouldDisable, AccessLevel, NoErpOrCompaniesErrorMessages, NoErpOrCompaniesImages } from '../util/AppUtil';
import NoErpOrCompaniesComponent from './NoErpOrCompaniesComponent';

const AddBillDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [loading, setLoading] = useState(false);
    const [addApiLoading, setAddApiLoading] = useState(false);
    const [attachment, setAttachment] = useState([]);

    const organization_id = props.captureSelectedOrganization.organization.id;
    const subscribed_quantity =  props.captureSelectedOrganization.organization?.company_quantity;
    const credit_points =  props.captureSelectedOrganization.organization?.credit_points;
    const selectedErpSoftwareList = props.captureSelectedOrganization.organization?.selected_erp_softwares || [];
    const {access_level} = props.captureSelectedOrganization;
    const accessLevelAdmin = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.ADMIN);
    
    const [selectedCompany, setSelectedCompanyID] = useState({});
    const [companies, setCompanies]=useState([]);
    const [isPurchase, setIsPurchase]=useState(true);

    const onCancelClick = () => {
        props.handleCloseAddBillDrawer();
    }

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        consoleToLog(files);
        setAttachment([...attachment, ...files]);
    }
/* ****************************** styles ************ */
    const fileUploaderContainer={
        display: 'flex',
        width:'100%',
        height: '6rem',
        justifyContent:'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        border: (attachment && attachment.length > 0) ? 'none' : '2px dashed #c5bebe'
    }

    const clearIcon= {
        minWidth:"20px", 
        padding:"0", 
        marginLeft:'8px'
    }

    const circularProgress= {
        marginLeft: 0,
        marginRight: '8px',
        color: 'white'
    }
/* ****************************** styles ************ */

    const getCaptureCompanies = () => {
        const organization_id = props.captureSelectedOrganization.organization.id;
        const source_erp_software = 'zoho';

        getLoggedInUserCompaniesApi(organization_id, source_erp_software)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getLoggedInUserCompaniesApi: ', res);
                setCompanies(res.companies);
            })
            .catch((e) => {
                consoleToLog('Error getLoggedInUserCompaniesApi', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    useEffect(() => {
        if(props.openAddNewBillDrawer) {
            getCaptureCompanies();
        }
    }, [props.openAddNewBillDrawer]);

    const onRemoveAttachment = (e, index) => {
        //e.stopPropagation();
        setAttachment(attachment.filter((item, i) => index !== i));
    }

    const showAttachment = (file, index) => {
        return (
            <Grid key={index} item container 
                alignItems='center'
                style={{marginTop: "8px", background:"#f7f7f7", padding:'8px', borderRadius:'5px'}}>
                <Grid item md={11}>
                    <Typography key={index} 
                        variant="body2" 
                        color="secondary" 
                        style={{fontSize: "14px"}}>
                        {file.name}
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Button onClick={(e) => onRemoveAttachment(e, index)} 
                        sx={clearIcon}>
                        <ClearIcon fontSize="small" color="secondary" style={{fontSize:'18px', color:'#555'}}/>
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const onAttachmentSelected = (e) => {
        const { target: { files } } = e;
        let localFilesArr = [];
        for(let i=0;i<files.length;i++){
            //path:company/:id/attachment_file_name_randomstr
            const fileType =  files[i].type;
            if(fileType && (fileType.toLowerCase() === FileExtenions.PDF)) {
                localFilesArr.push(files[i]);
            } else {
                
                if(fileType && (fileType.toLowerCase() === FileExtenions.JPEG || fileType.toLowerCase() === FileExtenions.PNG || fileType.toLowerCase() === FileExtenions.JPG)) {
                        if(files[i].size > 8e6) {
                            enqueueSnackbar(`Image file size exceeds 8MB limit`, {variant:'error'});
                        } else {
                            localFilesArr.push(files[i]);
                        }
                } else {
                    enqueueSnackbar(`File type is not supported`, {variant:'error'});
                }
            }
        }
        if(localFilesArr && localFilesArr.length > 0) {
            setAttachment(localFilesArr);
        }
    }

    const convertImageToPdf = (obj) => {
            var pdf = new jsPDF();
            const file = obj;

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    try {
                        const imgData = e.target.result;
                        var width = pdf.internal.pageSize.getWidth();
                        var height = pdf.internal.pageSize.getHeight();
                        pdf.addImage(imgData, null, 0, 0, width, height, null); // Adjust the dimensions and position as needed
                        const pdfBlob = pdf.output('blob');
            
                        // Create a new File object from the Blob
                        const pdfFile = new File([pdfBlob], file.name.replace(/\.[^/.]+$/, '.pdf'), {
                        type: 'application/pdf',
                        lastModified: new Date(),
                    });
                
                    // Return the PDF file object through the callback
                        resolve(pdfFile);
                    } catch (err) {
                        reject(err);
                    }
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(obj);
            });
    }
    

    const onUploadClick = async(e) => {
        e.preventDefault();

        const files = attachment;
        if (!files || files.length === 0) {
            enqueueSnackbar('Please attach files', {
                variant:'error'
            });
            return;
        }

        if (!selectedCompany) {
            enqueueSnackbar('Please select company!', {
                variant:'error'
            });
            return;
        }
        else {
            consoleToLog("selected company: ", selectedCompany)
        }

        let finalAttachments=[];
        for(let i=0;i<files.length;i++){
            //path:company/:id/attachment_file_name_randomstr
            const fileType =  files[i].type;
            let file_name;
            let file_type;
            let file;
            
            if(fileType && (fileType.toLowerCase() === FileExtenions.JPEG || fileType.toLowerCase() === FileExtenions.PNG || fileType.toLowerCase() === FileExtenions.JPG)) {
                let fileObj = await convertImageToPdf(attachment[i]);
                if(fileObj) {
                    file_name = fileObj.name;
                    file_type = fileObj.type;
                    file = fileObj
                } else {
                    return;
                }
            } else {

                file_name = attachment[i].name;
                file_type = attachment[i]?.type;
                file = attachment[i];
            }


            setLoading(true);
            await uploadAttachmentApi(organization_id, file_name, file_type,  file, selectedCompany?.c_id)
            .then((data) => {
                consoleToLog('upload files success', data);
                finalAttachments.push({path: data.file_name});
            })
            .catch((e) => {
                consoleToLog('upload error', e);
                setLoading(false);
            })
        }

        consoleToLog("finalAttchments to be sent: ", finalAttachments);
        //create invoice
        createInvoiceRecordApi(organization_id, selectedCompany?.c_id, finalAttachments, isPurchase)
        .then((response) => {
            const res = response.data;
            consoleToLog('upload files success', res);
            setLoading(false);
            props.setInvoiceObj(res.invoices);
            onCancelClick();
        })
        .catch((e) => {
            consoleToLog('upload error', e);
            setLoading(false);
            enqueueSnackbar(e.response.data.message, {
                variant:'error'
            });
        })
    }

    const handleChange = (e, newValue) => {
        consoleToLog("selected company: ", newValue)
        setSelectedCompanyID(newValue);
    }

    const checkIfErpSoftwareOrCompanyNotAdded = () => {
        let erpsoftwareListNotAdded = selectedErpSoftwareList && selectedErpSoftwareList.length === 0;
        if(erpsoftwareListNotAdded || (companies && companies.length === 0)) {
            return <NoErpOrCompaniesComponent 
                        src={erpsoftwareListNotAdded ? NoErpOrCompaniesImages.ADD_ERP_SOFTWARE_IMG : NoErpOrCompaniesImages.ADD_COMPANIES_IMG}
                        addErpSoftware={erpsoftwareListNotAdded ? true : false}
                        message={erpsoftwareListNotAdded ? NoErpOrCompaniesErrorMessages.ADD_ERP_SOFTWARE_MESSAGE : 
                            !accessLevelAdmin ? NoErpOrCompaniesErrorMessages.ADD_COMPANIES_FOR_ADMIN_MESSAGE : NoErpOrCompaniesErrorMessages.ADD_COMPANIES_FOR_MEMBER_MESSAGE}
                        fromAddBillDrawer={true}
                        handleCloseAddBillDrawer={props.handleCloseAddBillDrawer}
                        handleOpenAddCompanyDrawer={props.handleOpenAddCompanyDrawer}
            
                    />
        } else {
            return false;
        }

    }

    const handleRadioChange = (e) => {
        const value = e.target.value;
        if(value === 'purchase'){
            setIsPurchase(true);
        }
        else if(value === 'sales'){
            setIsPurchase(false);
        }
    }

    return (
        <DrawerComponent title='Add Bill'
            openDrawer={props.openAddNewBillDrawer}
            handleCloseDrawer={onCancelClick}>
            {checkIfErpSoftwareOrCompanyNotAdded() || 
            <div>
                <Container maxWidth='xl' disableGutters className='drawer_container'>
                    <Grid item sm={12}>
                        <Autocomplete size="small"
                            id="company_selector"
                            style={{width:'300px'}}
                            getOptionLabel={(option) => option.c_name || ''}
                            value={props.companyObj}
                            options={companies}
                            onChange={(e, newValue) => handleChange(e, newValue)}
                            renderInput={(params) => 
                                <TextField {...params} color="secondary"  variant="standard" placeholder="Please Select Company"
                                />}/>
                    </Grid>
                    {selectedCompany?.c_source_erp_software === 'tally' && <Grid item sm={12} sx={{marginTop: "8px"}}>
                        <FormControl component="fieldset" onChange={handleRadioChange}>
                            <RadioGroup row aria-label="position" name="position" defaultValue="tds">
                                <FormControlLabel value="purchase" control={<Radio color="secondary" checked={isPurchase} />} label="Purchase" />
                                <FormControlLabel value="sales" control={<Radio color="secondary" checked={!isPurchase}/>} label="Sales" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>}
                    <Grid item sm={12} sx={{marginTop: "20px"}}>
                        {attachment && attachment.length === 0 &&
                        <div>
                            <label style={fileUploaderContainer}
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={fileDrop}>   
                                <input style={{display:'none'}}
                                    type="file"
                                    id="companyListFile"
                                    accept=".pdf, .png, .jpg, .jpeg"
                                    multiple
                                    onChange={onAttachmentSelected}
                                />
                                <Grid item container justifyContent='center' style={{cursor:'pointer'}}>
                                    <AttachFileIcon sx={clearIcon} fontSize="small" color="secondary"/>
                                    <Typography variant="body2" color="secondary" style={{fontSize: "16px"}}>
                                        Attach Files
                                    </Typography>
                                </Grid>
                            </label>  
                        </div>}
                        {attachment && attachment.length > 0 &&
                            <div style={{marginTop:'16px'}}>
                                {(attachment && attachment.length > 0) &&
                                        attachment.map((file, index) => {
                                            return showAttachment(file, index);
                                        })
                                }
                            </div>
                        }
                    </Grid>
                </Container>

                {/* !checkOrgCompanyValidation(props.captureSelectedOrganization.organization) &&
                <Typography className={'error_message'}>
                    You are subscribed for {`${subscribed_quantity} ${subscribed_quantity === 1  ? 'company' : 'companies'}`}. Your have exceeded the limit. Archive some companies to start uploading bills.
                </Typography> */}
                {(props.captureSelectedOrganization.organization 
                    && props.captureSelectedOrganization.organization.credit_points<1) 
                    && <Typography className={'error_message'}>
                    You can not upload bills due to insufficient scan ponits. Kindly purchase!
                    </Typography>}
                <div className='drawer_footer'>
                    <Button onClick={onUploadClick} 
                        variant="contained" 
                        color="secondary" 
                        disabled={credit_points < 0/* !checkOrgCompanyValidation(props.captureSelectedOrganization.organization) ||  */}
                        style={{width:'130px'}}>
                        {loading && <CircularProgress size={20} style={circularProgress}/>}
                        Upload
                    </Button> 

                    <Button 
                        onClick={onCancelClick}
                        className='drawer_footer_btn'
                        variant='outlined' color='secondary'>
                        Cancel
                    </Button>
                </div>
            </div>}
        </DrawerComponent>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization
});

export default connect(mapStateToProps)(AddBillDrawer);