import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import {connect} from 'react-redux';
import {Box} from '@mui/material';
import ErrorSnackbarComponent from '../components/ErrorSnackbarComponent';
import theme from '../theme';
import { useState, useEffect } from "react";
import AddCompaniesDrawer from '../components/AddCompaniesDrawer';
import AddBillDrawer from '../components/AddBillDrawer';
import PurchaseCreditPointsDialog from "../components/PurchaseCreditPointsDialog";
import LeftMenuComponent from "../components/LeftMenuComponent";
import AddTallyConnectionDrawer from "../components/AddTallyConnectionDrawer";
import { ERPSoftwares, consoleToLog } from "../util/AppUtil";
import { Spinner } from "../components/Spinner";
import { callTallyConnectionApiFromUtil, clearIntervalFromUtil, createIntervalIfNoActiveInterval } from "../util/TallyUtil";
import { useIsMount } from "../components/useIsMount";
import { globalInterval } from "../util/intervalUtil";
import { useNavigate } from "react-router-dom";

const Layout = (props) => {
    const isMount = useIsMount();
    const navigate = useNavigate();

    const [openAddNewDrawer, setAddNewCompanyDrawer] = useState(false);
    const [openAddNewBillDrawer, setAddNewBillDrawer] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [companyList, setCompanyList] = useState([]);
    const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
    const [addTallyInfoDrawer, setAddTallyInfoDrawer] = useState(false);
    const [isTallyConnected, setIsTallyConnected] = useState(false);
    const [loading, setLoading] = useState(false);

    const pathname = window.location.pathname;
    const selectedErpSoftwareList = props.captureSelectedOrganization.organization?.selected_erp_softwares || [];
    //let interval;

    useEffect(()=>{
        if(!isMount) {
            if(props.user.tally_host && 
                props.user.tally_port && 
                selectedErpSoftwareList?.includes(ERPSoftwares.TALLY)) {
                //call api immediately
                checkTallyAppConnection();
            }
        }
    }, [props.captureSelectedOrganization.organization]);

    useEffect(()=>{
        if(Object.keys(props.captureSelectedOrganization).length === 0 && Object.keys(props.user).length === 0 && pathname !== '/' && !props.isLoading) {
            console.log('pathname**********', pathname);
            navigate('/', {state:{back_url: pathname}});
        }
    }, [pathname]);


    const handleOpenAddCompanyDrawer = () => {
        setAddNewCompanyDrawer(true);
    }

    const handleOpenAddBillDrawer = () => {
        setAddNewBillDrawer(true);
    }

    const handleCloseAddBillDrawer = () => {
        setAddNewBillDrawer(false);
    }

    const handleCloseAddCompanyDrawer = () => {
        setAddNewCompanyDrawer(false);
    }

    const handleOpenPurchaseDialog = () => {
        setOpenPurchaseDialog(true);
    }

    const handleClosePurchaseDialog = () => {
        setOpenPurchaseDialog(false);
    }

    const handleAddTallyInfoDrawerOpen = () => {
        setAddTallyInfoDrawer(true);
    }

    const handleAddTallyInfoDrawerClose = () => {
        setAddTallyInfoDrawer(false);
    }

    const drawerWidth = '13.5%';


    const checkTallyAppConnection = async() => {
        let base=props.user.tally_host+":"+props.user.tally_port;
        consoleToLog("base", base)
        base=window.btoa(base).toString('base64');

        try{
            let response=await callTallyConnectionApiFromUtil(base);
            response = response.data;

            consoleToLog("Response tallyAppConnectionCheckApi: ", response);    
            if(response && response.isTallytConnected){
                setIsTallyConnected(true);
                createIntervalIfNoActiveInterval(base, checkTallyAppConnection);
            }
            else{
                setIsTallyConnected(false);
            }
        }
        catch(err){
            consoleToLog("Error tallyAppConnectionCheckApi: ", err);
            console.log("calling interval clear");
            setIsTallyConnected(false);
            clearIntervalFromUtil();
        }
    }

    const updateTallyConnected=(isConnected)=>{
        setIsTallyConnected(isConnected);
    }


    return (
        <Box display='flex' flexDirection='column' height='100vh'>
            {Object.keys(props.captureSelectedOrganization).length > 0 && pathname !== '/payment/success' && pathname !== '/onboarding' &&
            <Header handleOpenAddCompanyDrawer={handleOpenAddCompanyDrawer}
                handleOpenAddBillDrawer={handleOpenAddBillDrawer}
                handleOpenPurchaseDialog={handleOpenPurchaseDialog}
                //tallyConnectMessage={tallyConnectMessage}
                isTallyConnected={isTallyConnected}
                updateTallyConnected={updateTallyConnected}
                handleAddTallyInfoDrawerOpen={handleAddTallyInfoDrawerOpen}
                setLoading={setLoading}/>}

                {loading ? <Spinner />
                :
                <Suspense fallback={ <Spinner/> }>
                    <Box display='flex'>
                        {Object.keys(props.captureSelectedOrganization).length > 0 && pathname !== '/onboarding' && pathname !== '/payment/success' &&
                        <Box width={drawerWidth}>
                            <LeftMenuComponent drawerWidth={drawerWidth}/>
                        </Box>}
                        
                        
                        <Box width={pathname !== '/onboarding' && pathname !== '/payment/success' ? '86.5%' : '100%'} display='flex'  
                            flexDirection='column'
                            flexGrow={1} sx={{background: theme.palette.primary.light, height: pathname !== '/onboarding' && pathname !== '/payment/success'  ? 'calc(100vh - 65px)' : '100vh'}}>
                            {!props.isFromOnboarding && <ErrorSnackbarComponent />}
                            
                            <Outlet  context={[invoiceObj, setInvoiceObj, handleOpenAddCompanyDrawer, handleOpenAddBillDrawer, companyList, setCompanyList, handleAddTallyInfoDrawerOpen, isTallyConnected, setIsTallyConnected]}
                                />
                        </Box>
                    </Box>
                </Suspense>}
                    
            <AddCompaniesDrawer openAddNewDrawer={openAddNewDrawer}
                handleCloseAddCompanyDrawer={handleCloseAddCompanyDrawer}
                setCompanyList={setCompanyList}
                handleCloseAddBillDrawer={handleCloseAddBillDrawer}/>

            {openAddNewBillDrawer && <AddBillDrawer openAddNewBillDrawer={openAddNewBillDrawer}
                handleCloseAddBillDrawer={handleCloseAddBillDrawer}
                setInvoiceObj={setInvoiceObj}
                handleOpenAddCompanyDrawer={handleOpenAddCompanyDrawer}/>}  

            {
                openPurchaseDialog && <PurchaseCreditPointsDialog openPurchaseDialog={openPurchaseDialog}
                    handleClosePurchaseDialog={handleClosePurchaseDialog}/>
            }

            {   addTallyInfoDrawer && 
                <AddTallyConnectionDrawer addTallyInfoDrawer={addTallyInfoDrawer}
                    handleAddTallyInfoDrawerClose={handleAddTallyInfoDrawerClose}/> 
            }
    </Box>
    );
}

const mapStateToProps = (state) => ({
    user: state.captureSelectedOrganizationInfo.user,
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    isFromOnboarding: state.captureSelectedOrganizationInfo.onboarding,
    isLoading: state.loading.isLoading
});

export default connect(mapStateToProps)(Layout)