import React, { useEffect, useState } from 'react';
import DrawerComponent from './DrawerComponent';
import { Container, Grid, Checkbox, Typography, Button, CircularProgress, Skeleton, List, ListItem, ListItemText} from '@mui/material';
import {getZohoOrganizationListApi} from '../services/captureAppService';
import {connect} from 'react-redux';
import { ERPSoftwares, consoleToLog } from '../util/AppUtil';
import {addCompaniesApi} from '../services/captureAppService';
import { useSnackbar } from 'notistack';
import {getRefreshedZohoToken} from '../services/zohoTokenService';
import {setZohoTokenObj} from '../actions/captureZohoToken';
import moment from 'moment';
import {checkOrgAddedCompanyAndSubscribedCompany} from '../util/AppUtil';
import { updateSelectedOrg } from '../actions/captureSelectedOrganization';
import { useNavigate } from 'react-router-dom';
import { updateOrganization } from '../actions/captureOrganizations';
import {Drawer, AppBar, Toolbar, Box, IconButton, Select, MenuItem} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useIsMount } from './useIsMount';
import { getConnectorAppVersionApi } from '../services/captureAppService';
import { NoErpOrCompaniesErrorMessages, NoErpOrCompaniesImages } from '../util/AppUtil';
import NoErpOrCompaniesComponent from './NoErpOrCompaniesComponent';
import { addOrUpdateZohoTokenToList } from '../actions/captureZohoTokenList';

const errorMessage = {
    color:"#D24E4E",
    background: '#ffe28f',
    fontSize: '13px',
    padding:'2px 6px',
    position:'relative',
    top:'-42px'
}

const MuiFormControlroot = {
    "& .MuiOutlinedInput-input": {
        paddingLeft: "16px",
        paddingTop:'8px',
        paddingBottom:'8px',
        background:'#fff',
    },
    "& .MuiSelect-select.Mui-disabled": {
        cursor: "default !important",
        background: "#fff !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
        background: "#fff !important",
        color: "#555555 !important",
    },
    "& .MuiInputBase-adornedEnd" : {
        backgroundColor: '#fff !important',
    }
};
const list_item = {
    display: "list-item", 
    padding: "4px 16px" 
}

const centerText={
   textAlign: "center"
}

const downloadLink = {
    color: '#20a8e0',
    cursor: 'pointer',
    fontSize:'14px',
    margin:"0px 1px 0px 8px",
    textAlign:"center",
    '&:hover': {
        textDecoration:'underline'
    }
}

const AddCompaniesDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const isMount = useIsMount();

    const [zohoOrgList, setZohoOrgList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOrgList, setSelectedOrgList] = useState([]);
    const [addApiLoading, setAddApiLoading] = useState(false);
    const [selectedErpSoftware, setSelectedErpSoftware] = useState('-1');
    const [tallyConnectMessage, setTallyConnectMessage] = useState('');
    const [showTallyAppDownloadLink, setShowTallyAppDownloadLink] = useState(undefined);

    const organization_id = props.captureSelectedOrganization.organization?.id;
    const subscribed_quantity =  props.captureSelectedOrganization.organization?.company_quantity;
    const selectedErpSoftwareList = props.captureSelectedOrganization.organization?.selected_erp_softwares || [];

    useEffect(() => {
        if(props.openAddNewDrawer) {
            if(selectedErpSoftwareList && selectedErpSoftwareList.length === 1)  {
                if(selectedErpSoftwareList?.includes(ERPSoftwares.ZOHO_BOOKS)) {
                    if(props.user.tokens && props.user.tokens.length === 0) {
                        enqueueSnackbar('You are not connected to Zoho. Please connect with Zoho.', {variant:'error'});
                        return;
                    } else {
                        getZohoOrgList();
                    }
                }
                
                if(selectedErpSoftwareList?.includes(ERPSoftwares.TALLY)) {
                    setTallyConnectMessage('Please add companies through Tally connector app');
                    getConnectorAppDownloadLink();
                }
            }
        }
    }, [props.openAddNewDrawer]);

    useEffect(() => {
        if(!isMount) {
            if(props.openAddNewDrawer) {
                if(selectedErpSoftware === ERPSoftwares.ZOHO_BOOKS) {
                    if(props.user.tokens && props.user.tokens.length === 0) {
                        enqueueSnackbar('You are not connected to Zoho. Please connect with Zoho.', {variant:'error'});
                        return;
                    } else {
                        getZohoOrgList();
                    }
                } else {
                        setTallyConnectMessage('Please add companies through Tally connector app');
                        getConnectorAppDownloadLink();
                }
            }
        }
    }, [selectedErpSoftware]);


    const getZohoOrgsForEachToken=async(tokenObj, localZohoOrgList)=>{
        let currentTimeStamp = moment().valueOf();
        setLoading(true);
        let tk_type = 'zoho';

        if(currentTimeStamp > tokenObj.token_expiration_timestamp) {
            const fetchData = async () => {
                try {
                    let tokenData = await getRefreshedZohoToken(organization_id, tk_type, props, tokenObj.email);
                    await callZohoOrgListApi(organization_id, tk_type, tokenData.result.access_token, tokenData.result.api_domain, tokenObj.email, localZohoOrgList)
                } catch(error) {
                    if(error.message);
                    return;
                }
            }
            fetchData();
        } else {
            await callZohoOrgListApi(organization_id, tk_type, tokenObj.zoho_access_token,  tokenObj.zoho_api_domain, tokenObj.email, localZohoOrgList)
        }
    }

    const getZohoOrgList = async() => {
        const tokenList=props.captureZohoTokenList;
        let localZohoOrgList=[];
        for(let i=0;i<tokenList.length;i++){
            //localZohoOrgList=zohoOrgList;
            await getZohoOrgsForEachToken(tokenList[i], localZohoOrgList);
        }
        console.log("localZohoOrgList: ", localZohoOrgList)
        setZohoOrgList(localZohoOrgList);
    }

    const callZohoOrgListApi = async(organization_id, tk_type, zoho_access_token, api_domain, email, localZohoOrgList) => {
        await getZohoOrganizationListApi(organization_id, tk_type, zoho_access_token, api_domain)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getZohoOrganizationListApi: ', res);
                setLoading(false);
                
                consoleToLog('zohoorg old list ', localZohoOrgList);
                consoleToLog('new res.zoho_orgs ', res?.zoho_orgs);

                if(res?.zoho_orgs && res?.zoho_orgs.length>0){
                    for(let i=0;i<res?.zoho_orgs.length;i++){
                        let obj=res?.zoho_orgs[i];
                        if(localZohoOrgList.filter(e => e.organization_id === obj.organization_id).length === 0) {
                            obj.zoho_email=email;
                            obj.zoho_token=zoho_access_token;
                            obj.zoho_api_domain=api_domain;
                            localZohoOrgList.push(obj);
                        }
                    }
                }
                
                consoleToLog("updated All zoho orgs: ", localZohoOrgList);
                
            })
            .catch((e) => {
                consoleToLog('Error getZohoOrganizationListApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const selectedOrg = (organizationId) => {
        const tempArr =  selectedOrgList.filter((org) => org.organization_id === organizationId);
        return tempArr.length > 0 ? true : false;
    }

    const handleChange = (e, selOrg) => {
        console.log('selec', selOrg);
        if(selectedOrg(selOrg.organization_id)) {
            const array = selectedOrgList.filter((org) => org.organization_id !== selOrg.organization_id);
            setSelectedOrgList(array);
        } else {
            setSelectedOrgList(oldOrgs => [...oldOrgs, selOrg])
        }
    }

    const onSaveClick = () => {
        if(selectedOrgList && selectedOrgList.length === 0) {
            enqueueSnackbar('Please select atleast one company to continue!', {variant:'error'});
            return;
        }

        const companies = selectedOrgList.map((company) => {
            return {
                source_id: company.organization_id,
                source_erp_software: 'zoho',
                source_state: company.state,
                source_tax_id:'',
                source_currency: company.currency_code,
                name: company?.name,
                zoho_email:company.zoho_email,
                zoho_access_token:company.zoho_token,
                api_domain: company.zoho_api_domain
            }
        })
        consoleToLog("selected companies",companies)
        let currentTimeStamp = moment().valueOf();
        let tk_type = 'zoho';
        setAddApiLoading(true);
        // if(currentTimeStamp > props.zohoTokenObj.token_expiration_timestamp) {
        //     const fetchData = async () => {
        //         try {
        //             let tokenData = await getRefreshedZohoToken(organization_id, tk_type, props);
        //             callAddCompaniesApi(tokenData.result.access_token, tokenData.result.api_domain, companies)
        //         } catch(error) {
        //             if(error.message);
        //             return;
        //         }
        //     }
        //     fetchData();
        // } else {
            callAddCompaniesApi(companies)
       // }

    }

    const callAddCompaniesApi = (companies) => {
        addCompaniesApi(organization_id, companies)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addCompaniesApi: ', res);
                enqueueSnackbar("Companies added successfully!!", {variant:'success'});
                setAddApiLoading(false);

                const obj = {
                    ...props.captureSelectedOrganization.organization,
                    //added_companies_count: res.added_companies_count,
                    onboarding: false
                }
                props.updateOrganization(obj);
                props.updateSelectedOrg(obj);
        
                !props.fromOnboardingComponent && props.setCompanyList(res.companies);
                props.fromOnboardingComponent && props.setCompaniesList(res.companies);
                onCancelClick();
            })
            .catch((e) => {
                setAddApiLoading(false);
                consoleToLog('Error addCompaniesApi', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onCancelClick = () => {
        props.handleCloseAddCompanyDrawer();
        setSelectedOrgList([]);
        setZohoOrgList([]);
        setSelectedErpSoftware('-1');
        setShowTallyAppDownloadLink(undefined);
    }

    const onPurchaseNowClick = () => {
        navigate('/settings', {state:{goToBilling: true}});
        onCancelClick();
    }

    const handleErpSoftwareChange = (e) => {
        const value = e.target.value;
        if(value === ERPSoftwares.ZOHO_BOOKS) setTallyConnectMessage('');
        setSelectedErpSoftware(e.target.value);
    }

    const showZohoCompaniesList = () => {
        return (<Grid item sm={12}>
                    <Typography variant='h8' component='p' sx={{marginBottom:'4px'}}>
                        Companies
                    </Typography>
                    {(loading ? Array.from(new Array(7)) : zohoOrgList).map((org, index) => {
                        return org ?
                                <Grid item md={12}
                                        sx={{
                                            backgroundColor: "#F9F9F9",
                                            padding:"8px",
                                            borderRadius: '4px',
                                            marginBottom:'8px',
                                            '&:last-child' : {
                                                marginBottom:'0px',
                                            }
                                        }}
                                        key={org.organization_id}>
                                    <Grid item container direction="column">
                                        <Grid item container alignItems='center'>
                                            <Grid item>
                                                <Checkbox color='secondary'
                                                    checked={selectedOrg(org.organization_id) || org.isAdded}
                                                    onChange={(e) => handleChange(e, org)}
                                                    value={org.name}
                                                    //disabled={org.isAdded || !checkOrgAddedCompanyAndSubscribedCompany(props.captureSelectedOrganization.organization)}
                                                    disabled={org.isAdded}
                                                />
                                            </Grid>

                                            <Grid item style={{marginLeft:'4px'}}>
                                                <Typography variant="body1" 
                                                    sx={{
                                                        color:'#555555', 
                                                        display:'flex', 
                                                        alignItems:'center'
                                                    }}>
                                                    {org.name}
                                                </Typography>
                                            </Grid>

                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Typography component="div" key={index} variant='h3'>
                                    <Skeleton /> 
                                </Typography>
                    }) 
                    }

                    {!loading && zohoOrgList && zohoOrgList.length === 0 &&
                    <Grid item container justifyContent='center'>
                        <Typography variant='body1'>
                            No Company List Found
                        </Typography>
                    </Grid>
                    }
                </Grid>
            )
    }

    const downloadConnectorApp = () => {
        document.getElementById('tally-connector-app').href = showTallyAppDownloadLink?.latest_version_url;
        document.getElementById('tally-connector-app').click();
    }

    const showTallyCompaniesList = () => {
        /* return (<Grid item sm={12}>
                    <Typography variant='h8' component='p' sx={{marginBottom:'4px', textAlign:'center'}}>
                        {tallyConnectMessage}
                    </Typography>
                    {showTallyAppDownloadLink?.latest_version_url &&
                    <Typography id='tally-connector-app' 
                        sx={downloadLink}
                        onClick={downloadConnectorApp}>
                        Download
                    </Typography>}
                </Grid>) */
                return (
                    <Grid item md={12}>
                        <Typography sx={centerText}>
                            Please Download Tally Connector App from below link.
                        </Typography>
                        {showTallyAppDownloadLink?.latest_version_url &&
                        <Typography id='tally-connector-app' 
                            sx={downloadLink}
                            onClick={downloadConnectorApp}>
                            Download
                        </Typography>}
                        <Typography style={{fontWeight: "bold"}}>
                            Steps for Tally:
                        </Typography>
                        <List sx={{ listStyle: "decimal", pl: 4 }}>
                            <ListItem sx={list_item}>
                                Open Tally
                            </ListItem>
                            <ListItem sx={list_item}>
                                Open client/server configuration dialog by navigating to Help => Settings => Connectivity
                            </ListItem>
                            <ListItem sx={list_item}>
                                In Client/Server configuration, set following values:
                                <ListItemText primary="- TallyPrime act as - Both"></ListItemText>
                                <ListItemText primary="- Enable ODBC - Yes"></ListItemText>
                                <ListItemText primary="- Port - 9000"></ListItemText>
                            </ListItem>
                        </List>
                        <Typography style={{fontWeight: "bold"}}>
                            Steps for Tally Connector:
                        </Typography>
                        <List sx={{ listStyle: "decimal", pl: 4 }}>
                            <ListItem sx={list_item}>
                                Download eProcessify Tally Connector App.
                            </ListItem>
                            <ListItem sx={list_item}>
                                Login with capture eProcessify credentials
                            </ListItem>
                            <ListItem sx={list_item}>
                            Navigate to Settings tab and  set the Tally host and port.
                            <ListItemText primary="Host- IP address of the machine where you change the connectivity configuration of the Tally software. If it is local machine, use http://localhost"></ListItemText>
                            <ListItemText primary="Port - Port number mentioned in Tally Software connectivity configuration"></ListItemText>
                            </ListItem>
                            <ListItem sx={list_item}>
                                <ListItemText primary="a. Navigate to Add companies tab. It shows all companies on disk."></ListItemText>
                                <ListItemText primary="b. Subscribe to companies you wanted to work on. To subscribe the company, you will need to load it on Tally."></ListItemText>
                            </ListItem>
                            <ListItem sx={list_item}>
                            Once companies are subscribed, they can be seen in My companies
                            </ListItem>
                            <ListItem sx={list_item}>
                            Now click Sync Master button to sync Tax Ledgers, Vendors, Account Head Ledgers, Round Off and Discount Ledgers into  eProcessify!
                            </ListItem>
                        </List>
                </Grid>
                )
    }

    const getConnectorAppDownloadLink = () => {
        getConnectorAppVersionApi(organization_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getConnectorAppVersionApi: ', res);
                setShowTallyAppDownloadLink(res.versions);
                
            })
            .catch((e) => {
                consoleToLog('Error getConnectorAppVersionApi', e);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const checkIfErpSoftwareNotAdded = () => {
        if(selectedErpSoftwareList && selectedErpSoftwareList.length === 0) {
            return <NoErpOrCompaniesComponent 
                        src={NoErpOrCompaniesImages.ADD_ERP_SOFTWARE_IMG}
                        addErpSoftware={true}
                        message={NoErpOrCompaniesErrorMessages.ADD_ERP_SOFTWARE_MESSAGE}
                        fromAddBillDrawer={false}
                        handleCloseAddCompanyDrawer={props.handleCloseAddCompanyDrawer}
            
                    />
        } else {
            return false;
        }

    }

    return (
        <Drawer anchor='right'
            open={props.openAddNewDrawer}
            onClose={onCancelClick}>
            <Box sx={{ flexGrow: 1, width:'450px'}}>
                <AppBar position="static" 
                    elevation={0}
                    sx={{backgroundColor:'#f8f8f8', color:'#202020', height:'45px', borderBottom:'1px solid #ccc'}}>
                    <Toolbar disableGutters  
                        style={{
                            padding: selectedErpSoftwareList && selectedErpSoftwareList.length > 1 ? '8px 8px 8px 16px' : '0px 8px 0px 16px', 
                            minHeight:"40px", height:"51px"}}>
                        <div style={{ flexGrow: 1}}>
                            {selectedErpSoftwareList && selectedErpSoftwareList.length > 1
                            ?
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedErpSoftware}
                                onChange={handleErpSoftwareChange}
                                sx={MuiFormControlroot}
                            >
                                <MenuItem value={'-1'}>Please select ERP Software</MenuItem>
                                {props.erpSoftwareList && props.erpSoftwareList.length > 0 &&
                                    props.erpSoftwareList.map((software) => {
                                        return <MenuItem key={software.value} value={software.value}>{software.name}</MenuItem>
                                    })
                                }
                            </Select>
                            :
                            <Typography variant="body1" >
                                Add Company
                            </Typography>}
                        </div>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={onCancelClick}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            {props.openAddNewDrawer &&
            <>
            <Container maxWidth='xl' disableGutters className='drawer_container'>
                {checkIfErpSoftwareNotAdded()
                    ||
                    <div>
                {(selectedErpSoftwareList.length === 1 && selectedErpSoftwareList?.includes(ERPSoftwares.ZOHO_BOOKS) || selectedErpSoftwareList.length > 1 && selectedErpSoftware === ERPSoftwares.ZOHO_BOOKS) && showZohoCompaniesList()}
                {(selectedErpSoftwareList.length === 1 && selectedErpSoftwareList?.includes(ERPSoftwares.TALLY) || selectedErpSoftwareList.length > 1 && selectedErpSoftware === ERPSoftwares.TALLY) && showTallyCompaniesList()}
                        {
                            selectedErpSoftwareList.length > 1 && selectedErpSoftware === '-1' &&
                            <Grid item container justifyContent='center' alignItems='center'>
                                <Typography>
                                    Please any one of the ERP Software to display companies
                                </Typography>
                            </Grid>
                        }
                    </div>
                }

                
            </Container>
            {/* (selectedErpSoftwareList.length === 1 && selectedErpSoftwareList?.includes(ERPSoftwares.ZOHO_BOOKS) || selectedErpSoftwareList.length > 1 && selectedErpSoftware === ERPSoftwares.ZOHO_BOOKS) && 
            !checkOrgAddedCompanyAndSubscribedCompany(props.captureSelectedOrganization.organization) &&
            <Typography className={'error_message'}>
                You are subscribed for {`${subscribed_quantity} ${subscribed_quantity === 1  ? 'company' : 'companies'}`}. Limit is reached. 
                <span style={{fontWeight:600, cursor:'pointer', marginLeft:'2px'}} onClick={onPurchaseNowClick}>
                    Purchase now
                </span> to add more company.
            </Typography> */}
            <div className='drawer_footer'>
                <Button 
                    //disabled={!checkOrgAddedCompanyAndSubscribedCompany(props.captureSelectedOrganization.organization)}
                    onClick={onSaveClick}
                    className='drawer_footer_btn'
                    variant='contained' color='secondary'>
                    {addApiLoading && <CircularProgress size={25} sx={{color:"#fff", marginRight:'16px'}} />}
                    Save
                </Button>

                <Button 
                    onClick={onCancelClick}
                    className='drawer_footer_btn'
                    variant='outlined' color='secondary'>
                    Cancel
                </Button>
            </div>
            </>}
            </Box>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    zohoTokenObj: state.captureZohoToken,
    user: state.captureSelectedOrganizationInfo.user,
    erpSoftwareList: state.captureSelectedOrganizationInfo.erpSoftwareList,
    captureZohoTokenList: state.captureZohoTokenList
});

const mapDispatchToProps = (dispatch) => ({
    setZohoTokenObj: (zoho_token_obj) => dispatch(setZohoTokenObj(zoho_token_obj)),
    updateOrganization: (organization) => dispatch(updateOrganization(organization)),
    updateSelectedOrg: (organization) => dispatch(updateSelectedOrg(organization)),
    addOrUpdateZohoTokenToList: (zoho_token_obj) => dispatch(addOrUpdateZohoTokenToList(zoho_token_obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCompaniesDrawer);