import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AccessLevel, shouldDisable} from '../util/AppUtil';
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

    const linkStyles = {
        color: '#20a8e0',
        cursor: 'pointer',
        fontSize:'13px',
        margin:"0px 1px 0px 8px",
    };

const ErrorSnackbarComponent = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    
    let zohoConnectUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.fullaccess.all,ZohoBooks.contacts.READ,ZohoBooks.bills.CREATE,ZohoBooks.accountants.READ,ZohoBooks.settings.READ,ZohoBooks.customerpayments.READ,ZohoBooks.banking.READ,ZohoBooks.bills.READ,ZohoBooks.bills.DELETE,ZohoBooks.customerpayments.READ,ZohoBooks.vendorpayments.CREATE,ZohoBooks.vendorpayments.UPDATE,ZohoBooks.vendorpayments.DELETE&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_ZOHO_REDIRECT_URL}&access_type=offline&prompt=consent`;

    const { access_level } = props.captureSelectedOrganization || {};
    const accessLevelAdmin = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.ADMIN);

    const onUpgradeClick = () => {
        navigate('/settings', {state:{goToBilling:true}});
    }

    return (
        <>
        {   
            // props.user.tokens && props.user.tokens.length === 0 &&
            // <Stack sx={{ width: '100%' }} spacing={2}>
            //     <Alert severity="error">You are not connected to Zoho. Please connect with Zoho. <Link className={'link_styles'} href={zohoConnectUrl} target='_self'>Connect</Link></Alert>
            // </Stack>
        }

        {
            /* props.user.tokens && props.user.tokens.length > 0 && props.captureSelectedOrganization &&  props.captureSelectedOrganization.organization && 
            !props.captureSelectedOrganization.organization.is_subscribed &&
            <Stack sx={{ width: '100%', marginTop:props.user.tokens && props.user.tokens.length === 0 && '4px' }} spacing={2}>
                <Alert severity="error">You are on free plan. <span className='link_styles' onClick={onUpgradeClick}>Upgrade</span></Alert>
            </Stack> */
        }

        </>
    );
}

const mapStateToProps = (state) => ({
    captureSelectedOrganization: state.captureSelectedOrganizationInfo.selectedOrganization,
    user: state.captureSelectedOrganizationInfo.user
})


export default connect(mapStateToProps)(ErrorSnackbarComponent);