import state from "../data/state.json";
import { MenuItem } from "@mui/material";
import moment from "moment";
import {Grid, Typography, Divider} from '@mui/material';
import ReactGA from "react-ga4";
import country from "../data/country.json";
import { authenticateApi } from "../services/authAppService";
import NoErpOrCompaniesComponent from "../components/NoErpOrCompaniesComponent";
import { SelectedDrawerState } from "../actions/drawer";

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const getQueryParams = (qs) => {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

export const currencyFormatter = new Intl.NumberFormat('en-IN', {
    style:'currency',
    currency: 'INR',
});

export const getCurrencyFormatter = (currency) => {
        currency=currency?.replaceAll("\"", "");
        return  new Intl.NumberFormat('en-IN', {
            style:'currency',
            currency: currency? currency : 'INR',
        });
}


export const displayCurrencyFormattedAmountFromUtil=(invoiceObj, amount)=>{
    if(invoiceObj && invoiceObj.invoice_currency){
        return getCurrencyFormatter(invoiceObj.invoice_currency).format(amount)
    }
    else{
        return Number(amount).toFixed(2);
    }
}

export const getStatusColor = (status) => {
    if (!status) return;
    var color = '#929292';
    switch (status) {
        case 'pending':
        case 'Pending':
        case 'pending_approval':
        case 'Pending Approval':
            color = '#929292';
            break;
        case 'draft':
        case 'Draft':
            color = '#ffa834';
            break;
        case 'posted':
        case 'Posted':
        case 'processed':
        case 'Processed':
            color = '#268ddd';
            break;
        case 'ready':
        case 'Ready':
        case 'processing':
        case 'Processing':
            color = '#1A73E8';
            break;
        case 'rejected':
        case 'Rejected':
        case 'invalid':
        case 'Invalid':
            color = '#D24E4E';
            break;  
        case 'partially_paid':
        case 'Partially Paid':
        case 'paid':
        case 'Paid':
            color = '#1fcd6d';
            break;  
        case 'overdue':
        case 'Overdue':
            color = '#D24E4E';
            break;  
        default:
            color = '#929292';
    }
    return color;
}

//input: 'abc' output: 'Abc'
export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const randomString = (anysize) =>{
    const charset='BCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var res = '';
    while (anysize--) res += charset[Math.random() * charset.length | 0];
    return res;
}

const {countries} = country;
const {states} = state;

export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>
        )
    })
)

export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.name}>{state.name}</MenuItem>
        )
    })
);

export const getMatchedState=(capturedPlaceOfSupply)=>{
    const localStates=states.filter((state)=>{
        return state.name === capturedPlaceOfSupply || state.code===capturedPlaceOfSupply
    })

    if(localStates && localStates.length>0){
        return localStates[0].name;
    } 
    else return "";
}

export const AccessLevel = {
    ADMIN: 2,
    MEMBER: 1,
    getUserAccessLevelValue: function (access_level) {
        switch (access_level) {
            case "admin": return 2;
            case "member": return 1;
        }
    }
};

export const shouldDisable = (user_access, actual_permission) => {
    return !(user_access >= actual_permission);
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const removeSymbolsFromNumString = (numString) => {
    let str = numString ? numString.toString() : ''; 
    //console.log("quantity: ", str)
    str=str?.replace('Rs.', '');
    str=str?.replace(/[^\d.-]/g, '');
    //console.log("quantity after: ", str)
    return str ? Number(str?.replace(/[^\d.-]/g, '')) : '';
}

export const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
}

export const getLabelFieldsArr = (fieldsArr, taxType) => {
    return  fieldsArr?.find(item => item.labelDetection?.text?.includes(taxType));
}

export const getFieldsArr = (fieldsArr, itemType) => {
    return  fieldsArr?.find(item => item.type === itemType);
}

export const getHSNFromUtil=(fieldsArr)=>{
    return fieldsArr?.find(item => item?.labelDetection?.text.toLowerCase()==='hsn' || item?.labelDetection?.text.toLowerCase()==='sac' || item?.labelDetection?.text.toLowerCase()==='hsn/sac');
}

export const getTaxLabel=(fieldsArr, itemType, arr, isPlaceOfSupplyChanged)=>{
    //if place supply changed manually then dont consider tax label.
    if(isPlaceOfSupplyChanged){
        return ""
    }
    for(let i=0;i<fieldsArr.length;i++){
        //type=tax and match string
        if(fieldsArr[i].type === itemType 
            && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='sgst'){
                return 'gst';
        }
        if(fieldsArr[i].type === itemType 
            && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='cgst'){
                return 'gst';
        }
        if(fieldsArr[i].type === itemType 
            && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='igst'){
                return 'igst';
        }

        // if type = tax not found, then find for text only
        if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='sgst'){
                return 'gst';
        }
        if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='cgst'){
                return 'gst';
        }
        if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='igst'){
                return 'igst';
        }
    }

    let SummaryFields = [...arr.map(document => document.SummaryFields)].flat();
    console.log('***SummaryFields:', SummaryFields);
    for(let i=0;i<SummaryFields.length;i++){
        if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('sgst')){
            return 'gst';
        }

        if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('cgst')){
            return 'gst';
        }
        if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('igst')){
            return 'igst';
        }

        if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('sgst')){
            return 'gst';
        }

        if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('cgst')){
            return 'gst';
        }
        if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('igst')){
            return 'igst';
        }
    }
}

export const getTaxFieldsArr = (fieldsArr, itemType) => {
    //return  fieldsArr?.find(item => item.type === itemType);
    let tax=0;
    for(let i=0;i<fieldsArr?.length;i++){
        if(fieldsArr[i].type === itemType){
            let local_tax=removeSymbolsFromNumString(fieldsArr[i]?.content?.text);
            console.log("line item local tax: ", local_tax);
            if(local_tax){
                tax=tax+local_tax;
            }
        }
    }
    consoleToLog("line item tax: ", tax);
    return tax;
}

export const getHsnFromSummaryField=(fieldsArr)=>{
    let hsc_sac='';
    for(let i=0;i<fieldsArr.length;i++){
        //console.log("**fieldsArr[i]", fieldsArr[i]);
        if((fieldsArr[i]?.LabelDetection && fieldsArr[i]?.LabelDetection?.Text?.toLowerCase()==='hsn')
            || (fieldsArr[i]?.LabelDetection && fieldsArr[i].LabelDetection.Text?.toLowerCase()==='sac')
            || (fieldsArr[i]?.LabelDetection && fieldsArr[i].LabelDetection.Text?.toLowerCase()==='hsn/sac')){
            hsc_sac=fieldsArr[i]?.ValueDetection?.Text;
            console.log("**hsn 1", hsc_sac);
            hsc_sac=removeSymbolsFromNumString(hsc_sac);
            console.log("**hsn 2", hsc_sac);
            if(hsc_sac){
                break;
            }
            else{
                continue;
            }
        }
    }
    consoleToLog("hsn3 ", hsc_sac);
    return hsc_sac;
}

export const getTaxFromSummaryField=(fieldsArr, itemType)=>{
    //return  fieldsArr?.find(item => item.Type.Text === itemType);

    let tax=0;
    for(let i=0;i<fieldsArr.length;i++){
        if(fieldsArr[i].Type.Text === itemType){
            let local_tax=removeSymbolsFromNumString(fieldsArr[i]?.ValueDetection?.Text);
            console.log("summary field local tax: ", local_tax);
            if(local_tax){
                tax=tax+local_tax;
            }
        }
    }
    consoleToLog("summary field final tax: ", tax);
    return tax;
}

export const ItemTypes = {
    ITEM: "ITEM", 
    UNIT_PRICE: "UNIT_PRICE", 
    PRICE: "PRICE", 
    QUANTITY: "QUANTITY", 
    PRODUCT_CODE: "PRODUCT_CODE", 
    EXPENSE_ROW: "EXPENSE_ROW", 
    TAGS: "TAGS",
    VENDOR_NAME: "VENDOR_NAME",
    INVOICE_RECEIPT_DATE: "INVOICE_RECEIPT_DATE",
    DUE_DATE: "DUE_DATE",
    INVOICE_RECEIPT_ID: "INVOICE_RECEIPT_ID",
    TAX: "TAX",
    TOTAL: "TOTAL",
    SUBTOTAL: "SUBTOTAL",
}

export const getZohoTokenObj = (resObj) => {
    const originalTimestamp = resObj.expiration_timestamp;
    // Convert the timestamp to a Moment.js object
    const originalMoment = moment(originalTimestamp);

    // Subtract 2 minutes
    const newMoment = originalMoment.add(58, 'minutes');

    const newTimestamp = newMoment.valueOf();

    return {
        zoho_access_token: resObj.result?.access_token,
        zoho_api_domain: resObj.result?.api_domain,
        token_expiration_timestamp: newTimestamp,
        email: resObj.email,
        zohoOrgList: resObj.zohoOrgList
    }
}

export const getAssigneesFromInvoiceItem = (invoiceItem) => {
    return (invoiceItem.assignee && invoiceItem.assignee.length > 0) ? invoiceItem.assignee : [];
}

export const getApproversFromInvoiceItem = (invoiceItem) => {
    return (invoiceItem.approver && invoiceItem.approver.length > 0) ? invoiceItem.approver : [];
}

const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

//return if valid, return error if not valid
export const invoiceLineItemsValidation = (invoiceLineItemsArr, reverseCharge) => {
    for (var i = 0; i < invoiceLineItemsArr.length; i++) {
        if (!invoiceLineItemsArr[i].account_id) {//empty
            return `Account not selected for ${ordinal_suffix_of(i + 1)} line item`;
        }
        // if(!invoiceLineItemsArr[i].gst_treatment_code) {
        //     if (reverseCharge ? !invoiceLineItemsArr[i].reverse_charge_tax_id : !invoiceLineItemsArr[i].tax_id) {//empty
        //         return `Tax not selected for ${ordinal_suffix_of(i + 1)} line item`;
        //     }
        // }
        if(!invoiceLineItemsArr[i].quantity || isNaN(invoiceLineItemsArr[i].quantity)){
            return `Invalid Quantity for ${ordinal_suffix_of(i + 1)} line item`;
        }

    }
}

export const isErrorInInvoiceLineItems = (invoiceLineItemsArr, subtotal, isInvoiceItemized) => {
    let isErrorInLineItem= false;
    let localSubTotal=0;
    consoleToLog("##invoiceLineItemsArr: ", invoiceLineItemsArr)
    for (var i = 0; i < invoiceLineItemsArr.length; i++) {
        if(isInvoiceItemized){
            if(invoiceLineItemsArr[i].quantity==="" || isNaN(invoiceLineItemsArr[i].quantity)){
                isErrorInLineItem=true;
                break;
            }
            if(invoiceLineItemsArr[i].rate==="" || isNaN(invoiceLineItemsArr[i].rate)){
                isErrorInLineItem=true;
                break;
            }
            consoleToLog("##quantity: ", invoiceLineItemsArr[i].quantity)
            consoleToLog("##rate: ", invoiceLineItemsArr[i].rate);
            localSubTotal=localSubTotal+invoiceLineItemsArr[i].rate*invoiceLineItemsArr[i].quantity
        }
        else{
            if(invoiceLineItemsArr[i].price==="" || isNaN(invoiceLineItemsArr[i].price)){
                isErrorInLineItem=true;
                break;
            }
            consoleToLog("##price: ", invoiceLineItemsArr[i].price)
            localSubTotal=localSubTotal+Number(invoiceLineItemsArr[i].price)
        }
    }
    consoleToLog("****validate Subtotal: ", subtotal);
    consoleToLog("****validate local Subtotal: ", localSubTotal);
    if(!isErrorInLineItem && localSubTotal !== subtotal){
        isErrorInLineItem=true;
    }
    return isErrorInLineItem;
}

export const InvoiceStatus = {
    DRAFT: 'draft',
    PENDING: 'pending',
    POSTED: 'posted',
    PAID: 'paid',
    OVERDUE: 'overdue',
    PARTIALLY_PAID: 'partially_paid'
}

export const showSuccessMessage = () =>{
    return (
        <Grid item container alignItems='center' spacing={1}
            sx={{marginTop: "10px", padding:'10px 15px 15px 15px', background:'#506d3f', color:'#fff'}}>
            <Grid item>
                <img src={'/images/check_circle.svg'} width='10px'/>
            </Grid>

            <Grid item>
                <Typography>
                    Your invoice is successfully validated
                </Typography>
            </Grid>
        </Grid>
    )
}

export const showErrors = (errorList) => {
    return (
        <div className="errorContainer">
            <Grid container direction='column' sx={{gap:"8px"}}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item>
                        <img src="/images/warning.svg" width='24px' />
                    </Grid>

                    <Grid item>
                        <Typography>
                            You have these errors in your invoice
                        </Typography>
                    </Grid>
                </Grid>
                {
                    errorList && errorList.length > 0 &&
                    errorList.map((error) => {
                        return  <div key={error}>
                                    <Grid item container justifyContent='space-between'>
                                        <Grid item>
                                            <Typography>
                                                {error}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            {/* <Typography sx={ignoreText}>
                                                Ignore
                                            </Typography> */}
                                        </Grid>
                                    </Grid>
                                    <Divider className="dividerstyle" orientation="horizontal"/>
                                </div>
                    })
                }
            </Grid>
        </div>
    )
}

export const showErrowAndClearReduxOn401 = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, { variant: 'error' });
        props.logout();
        props.clearOrgs();
        props.setClearOrgState();
        props.clearZohoTokenObj();
        props.setSelectedDrawerState('');
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=capture`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const lineItemObj = {
    fields: [
        {
            comment: {emoji: ""},
            content: {text: ""},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "PRODUCT_CODE"
        },
        {
            comment: {emoji: ""},
            content: {text: "1"},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "QUANTITY"
        },
        {
            comment: {emoji: ""},
            content: {text: ""},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "UNIT_PRICE"
        },
        {
            comment: {emoji: ""},
            content: {text: ""},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "PRICE"
        },
        {
            comment: {emoji: ""},
            content: {text: ""},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "ITEM"
        },
        {
            comment: {emoji: ""},
            content: {text: ""},
            id: Math.random().toString(36).substr(2, 9),
            position: {boundingRect: {}, rects: [{}], pageNumber: 1},
            type: "EXPENSE_ROW"
        }
    ]
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const base64ToArrayBuffer = (base64) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const BillCustomFieldsDataTypes =  {
    STRING:'string',
    DATE:'date',
    DROPDOWN: 'dropdown',
    AUTONUMBER: 'autonumber',
    MULTILINE: 'multiline'
}

export const phoneNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const getStatusName = (status) => {
    if (!status) return;
    switch (status) {
        case 'processing':
            return 'Processing';
        case 'pending':
            return 'Pending';
        case 'draft':
            return 'Draft';
        case 'posted':
            return 'Posted';
        case 'partially_paid':
            return 'Partially Paid';
        case 'overdue':
            return 'Overdue';
        case 'paid':
            return 'Paid';
        default:
            return  '';
    }
}

/* export const checkOrgCompanyValidation = (selectedOrg) => {
    const subscribed_company = selectedOrg.company_quantity;
    const added_companies_count = selectedOrg.added_companies_count;

    if(added_companies_count > subscribed_company) {
        return false;
    } else {
        return true;
    }
} */

/* export const checkOrgAddedCompanyAndSubscribedCompany = (selectedOrg) => {
    const subscribed_company = selectedOrg.company_quantity;
    const added_companies_count = selectedOrg.added_companies_count;

    if(added_companies_count >= subscribed_company) {
        return false;
    } else {
        return true;
    }
} */

export const numberValidate = (e) => {
    const re = /[0-9 ()+-.]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}


export const connectToZoho = () => {
    let zohoConnectUrl = `https://accounts.zoho.in/oauth/v2/auth?scope=Aaaserver.profile.read,ZohoBooks.fullaccess.all,ZohoBooks.contacts.READ,ZohoBooks.bills.CREATE,ZohoBooks.accountants.READ,ZohoBooks.settings.READ,ZohoBooks.customerpayments.READ,ZohoBooks.banking.READ,ZohoBooks.bills.READ,ZohoBooks.bills.DELETE,ZohoBooks.customerpayments.READ,ZohoBooks.vendorpayments.CREATE,ZohoBooks.vendorpayments.UPDATE,ZohoBooks.vendorpayments.DELETE&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_ZOHO_REDIRECT_URL}&access_type=offline&prompt=consent`;
    window.open(zohoConnectUrl, '_self');
}

export const trackGAPageView = (page) => {
    ReactGA.pageview(page);
}

export const trackGAEvent = (category, action, label) => {
    if(!isDebug()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
}


export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};

export const showAllStateForZoho = () => (
    states.map((state)=>{
        return(
            <MenuItem key={state.code} value={state}>{state.name}</MenuItem>
        )
    })
)

//str splitter - tally doesnt show long string clearly so splitting
export const splitter=(str, l)=>{
    var strs = [];
    if(str){
        while(str.length > l){
            var pos = str.substring(0, l).lastIndexOf(' ');
            pos = pos <= 0 ? l : pos;
            strs.push(str.substring(0, pos));
            var i = str.indexOf(' ', pos)+1;
            if(i < pos || i > pos+l)
                i = pos;
            str = str.substring(i);
        }
        strs.push(str);
    }
    return strs;
}

export const extractVendorAddress=(option)=>{
    if(option && Object.keys(option).length>0){
        let localOption = option['ADDRESS.LIST']?.ADDRESS || "";
        let name='';
        if(localOption){
            name=localOption;
        }
        return name;
    }
    else return "";
  }

export const getTaxLabelForZoho = (lineItemArr, itemType, arr) => {
    const updatedArray = lineItemArr.map((item) => {
        let fieldsArr = item.fields.LineItemExpenseFields;

        for(let i=0;i<fieldsArr.length;i++){
            //type=tax and match string
            if(fieldsArr[i].type === itemType 
                && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='sgst'){
                    return 'gst';
            }
            if(fieldsArr[i].type === itemType 
                && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='cgst'){
                    return 'gst';
            }
            if(fieldsArr[i].type === itemType 
                && fieldsArr[i]?.labelDetection?.text.toLowerCase()==='igst'){
                    return 'igst';
            }
    
            // if type = tax not found, then find for text only
            if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='sgst'){
                    return 'gst';
            }
            if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='cgst'){
                    return 'gst';
            }
            if(fieldsArr[i]?.labelDetection?.text.toLowerCase()==='igst'){
                    return 'igst';
            }
        }
    
        let SummaryFields = [...arr.map(document => document.SummaryFields)].flat();
        //console.log('***SummaryFields:', SummaryFields);
        for(let i=0;i<SummaryFields.length;i++){
            if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('sgst')){
                return 'gst';
            }
    
            if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('cgst')){
                return 'gst';
            }
            if(SummaryFields[i].Type.Text === itemType && SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('igst')){
                return 'igst';
            }
    
            if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('sgst')){
                return 'gst';
            }
    
            if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('cgst')){
                return 'gst';
            }
            if(SummaryFields[i]?.LabelDetection?.Text?.toLowerCase().includes('igst')){
                return 'igst';
            }
        }
    });
    return updatedArray && updatedArray.length > 0 ? updatedArray[0] : '';
}

export const getStateObj = (stateName) => {
    const stateObj = states.find((state) => state.name.toLowerCase() === stateName?.replace(/[^\w\s]/g, '').toLowerCase() || state.code.toLowerCase() === stateName?.toLowerCase());
    return stateObj;
}

export const encryptReduxState = async(openpgp, serializeData) => {
    try {
        const passphrase = process.env.REACT_APP_ENC_TEXT_PASSPHRASE;
        const decodedPublicKey = window.atob(process.env.REACT_APP_ENCRP_PUBLIC_KEY);
        const decodedPrivateKey = window.atob(process.env.REACT_APP_ENCRP_PRIVATE_KEY);

        const publicKey1 = await openpgp.readKey({ armoredKey: decodedPublicKey });
        const privateKey1 = await openpgp.decryptKey({
            privateKey: await openpgp.readPrivateKey({ armoredKey: decodedPrivateKey }),
            passphrase
        });

        const encrypted = await openpgp.encrypt({
            message: await openpgp.createMessage({ text: serializeData }), // input as Message object
            encryptionKeys: publicKey1,
            signingKeys: privateKey1 // optional
        });
        
        //console.log("******text: ", encrypted)
        return encrypted;
    } catch(err) {
        //console.log("**update err in encrypt:", err);
    }
}

export const decryptReduxState = async(openpgp, persistedState) => {
    try {
        const passphrase = process.env.REACT_APP_ENC_TEXT_PASSPHRASE; 
        const decodedPublicKey = window.atob(process.env.REACT_APP_ENCRP_PUBLIC_KEY);
        const decodedPrivateKey = window.atob(process.env.REACT_APP_ENCRP_PRIVATE_KEY);

        const publicKey1 = await openpgp.readKey({ armoredKey: decodedPublicKey });
        const privateKey1 = await openpgp.decryptKey({
            privateKey: await openpgp.readPrivateKey({ armoredKey: decodedPrivateKey }),
            passphrase
        });

        const message = await openpgp.readMessage({
        //encrypted token
            armoredMessage: persistedState // parse armored message
        });

        const { data: decrypted, signatures } = await openpgp.decrypt({
            message,
            verificationKeys: publicKey1, // optional
            decryptionKeys: privateKey1
        });
        //consoleToLog("decrypted text::", JSON.parse(decrypted));
        return JSON.parse(decrypted)

    } catch(err) {
        console.log("**update err in decrypt:", err);
    }
}  

export const FileExtenions = {
    PNG: "image/png",
    JPEG: "image/jpeg",
    JPG: "image/jpg",
    PDF: "application/pdf"
}

//erp functions
export const ERPSoftwares = {
    ZOHO_BOOKS: 'zoho_books',
    TALLY: 'tally'
}

export const TallyConnectMessage = {
    CONNECTED: "TallyPrime Server is Running"
}

export const getErpSoftwareName=(erpSoftwareList, erp_key)=>{
    const nameList=erpSoftwareList.filter((obj)=>{
        return obj.value===erp_key;
    })
    return (nameList && nameList.length>0) ? nameList[0].name : "";
}

export const isErpConnected=(erpStr, zohoTokenPresent, isTallyConnected)=>{
    if(erpStr=== ERPSoftwares.ZOHO_BOOKS){
        return zohoTokenPresent;
    }
    else if(erpStr=== ERPSoftwares.TALLY){
        return isTallyConnected
    }
}

export const getErpIconDependOnConnection=(selectedErpSoftwareList, zohoTokenPresent, isTallyConnected)=>{
    let image='/images/red-disconnected.png', red=false, green=false;

    if(selectedErpSoftwareList && selectedErpSoftwareList.length === 0) {
        red=true
    } else {
        for(let i=0;i<selectedErpSoftwareList.length;i++){
            const erpStr=selectedErpSoftwareList[i];
            if(erpStr=== ERPSoftwares.ZOHO_BOOKS 
                && isErpConnected(erpStr, zohoTokenPresent, isTallyConnected) ){
                    green=true;
            }
            if(erpStr=== ERPSoftwares.TALLY 
                && isErpConnected(erpStr, zohoTokenPresent, isTallyConnected)){
                    green=true;
            }
            if(erpStr=== ERPSoftwares.ZOHO_BOOKS 
                && !isErpConnected(erpStr, zohoTokenPresent, isTallyConnected)){
                    red=true;
            }
            if(erpStr=== ERPSoftwares.TALLY 
                && !isErpConnected(erpStr, zohoTokenPresent, isTallyConnected)){
                    red=true;
            }
        }
    }

    if(green && !red){
        image="/images/green-connected.png";
    }
    if(!green && red){
        image = '/images/red-disconnected.png'
    }
    if(green && red){
        image = '/images/orange-disconnected.png'
    }

    return image;
}

export const NoErpOrCompaniesErrorMessages = {
    ADD_ERP_SOFTWARE_MESSAGE: "You have not added accounting/ERP software. Add and Connect your accounting/ERP software to start uploading bills.",
    ADD_COMPANIES_FOR_ADMIN_MESSAGE: "You have not added any company. Please add company to start uploading bills",
    ADD_COMPANIES_FOR_MEMBER_MESSAGE: "Administrator have not added companies. Once a company is added, you will be able to upload a bill."
}

export const NoErpOrCompaniesImages = {
    ADD_ERP_SOFTWARE_IMG: "/images/no-activity-found.svg",
    ADD_COMPANIES_IMG: "/images/no_checklist.svg",
}

export const connectToERPSoftware = (navigate, props) => {
    navigate('/profile', {state:{showIntegrateTab: true}});
    props.setSelectedDrawerState(SelectedDrawerState.SHOW_MY_PROFILE);
}

// export const checkIfErpSoftwareOrCompanyNotAdded = (selectedErpSoftwareList, fromAddBillDrawer, handleCloseAddBillDrawer, companyList, accessLevelAdmin, handleOpenAddCompanyDrawer) => {
//     if (selectedErpSoftwareList && selectedErpSoftwareList.length === 0) { 
//         return <NoErpOrCompaniesComponent src={NoErpOrCompaniesImages.ADD_ERP_SOFTWARE_IMG}
//                     addErpSoftware={true}
//                     message={NoErpOrCompaniesErrorMessages.ADD_ERP_SOFTWARE_MESSAGE}
//                     fromAddBillDrawer={fromAddBillDrawer}
//                     handleCloseAddBillDrawer={handleCloseAddBillDrawer}

//         />
//     } else if(fromAddBillDrawer && companyList && companyList.length === 0) {
//         return <NoErpOrCompaniesComponent src={NoErpOrCompaniesImages.ADD_COMPANIES_IMG}
//                     addCompany={true}
//                     message={!accessLevelAdmin ? NoErpOrCompaniesErrorMessages.ADD_COMPANIES_FOR_ADMIN_MESSAGE : NoErpOrCompaniesErrorMessages.ADD_COMPANIES_FOR_MEMBER_MESSAGE}
//                     handleOpenAddCompanyDrawer={handleOpenAddCompanyDrawer}
//                     handleCloseAddBillDrawer={handleCloseAddBillDrawer}
//         />
//     } else {
//         return false;
//     }
// }

export const getQuantity = (item) => {
    //console.log("item: ", item);
    const quantityObj = getFieldsArr(item.fields.LineItemExpenseFields, ItemTypes.QUANTITY);
    return quantityObj;
}

export const getRate = (item) => {
    //console.log("item: ", item)
    const rateObj = getFieldsArr(item.fields.LineItemExpenseFields, ItemTypes.UNIT_PRICE);
    return rateObj;
}

export const getPrice = (item) => {
    //console.log("item: ", item)
    const priceObj = getFieldsArr(item.fields.LineItemExpenseFields, ItemTypes.PRICE);
    return priceObj;
}

export const getItemName = (item) => {
    //console.log("item: ", item);
    const itemObj = getFieldsArr(item.fields.LineItemExpenseFields, ItemTypes.ITEM);
    return itemObj;
}

export const updateHash = (highlight) => {
    document.location.hash = highlight ? `highlight-${highlight.id}` : ''
}

export const getZohoOrgIdList = (list) => {
    const localCaptureZohoTokenList = list;
        
    let zohoOrgIdList=[];
    if(localCaptureZohoTokenList && localCaptureZohoTokenList.length>0){
        for(let i=0;i<localCaptureZohoTokenList.length;i++){
            if(localCaptureZohoTokenList[i].zohoOrgList && localCaptureZohoTokenList[i].zohoOrgList.length>0){
                let filteredList=localCaptureZohoTokenList[i].zohoOrgList.map((org)=>org.organization_id);
                zohoOrgIdList=zohoOrgIdList.concat(filteredList);
            }
        }
    }
    
    return zohoOrgIdList;
}


export const getSelectedToken=(list, zoho_organization_id)=>{
    consoleToLog("list: ",list);
    consoleToLog("zoho_organization_id: ", zoho_organization_id);
    const localCaptureZohoTokenList = list;
    let selectedToken=undefined;
    if(localCaptureZohoTokenList && localCaptureZohoTokenList.length>0){
        for(let i=0;i<localCaptureZohoTokenList.length;i++){
            if(localCaptureZohoTokenList[i].zohoOrgList && localCaptureZohoTokenList[i].zohoOrgList.length>0){
                // let filteredList=localCaptureZohoTokenList[i].zohoOrgList.map((org)=>org.organization_id);
                // zohoOrgIdList=zohoOrgIdList.concat(filteredList);
                for(let j=0;j<localCaptureZohoTokenList[i].zohoOrgList.length;j++){
                    let orgIdlist=localCaptureZohoTokenList[i].zohoOrgList.filter((org)=> org.organization_id===zoho_organization_id);
                    if(orgIdlist && orgIdlist.length>0){
                        selectedToken=localCaptureZohoTokenList[i];
                        consoleToLog("selectedToken: ",selectedToken);
                        break;
                    }
                }
                if(selectedToken){
                    break;
                }
            }
           
        }
    }
    return selectedToken;
}

export const InvoiceType =  {
    PURCHASE:'purchase',
    SALES:'sales'
}