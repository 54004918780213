export const SelectedDrawerState  = {
    SHOW_PURCHASES  : 'purchases',
    SHOW_COMPANIES : 'companies',
    SHOW_ORGANIZATION_SETTINGS : 'settings',
    SHOW_MY_PROFILE: 'profile',
    SHOW_SALES: "sales"
};

export const setSelectedDrawerState = (drawerState) =>  ({
    type: 'SET_SELECTED_DRAWER_STATE',
    drawerState
})